import React from 'react';
import { Button, Dropdown, MenuProps, Space } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import styled from 'styled-components/macro';

import LineageVizTimeSelector from './LineageVizTimeSelector';
import { useIsSeparateSiblingsMode } from '../../entity/shared/siblingUtils';
import { useIsShowColumnsMode } from '../utils/useIsShowColumnsMode';
import { LineageVizToggles } from './LineageVizToggles';
import { FetchedEntity } from '../types';
import ExportLineageAsCsv from './ExportLineageAsCsv';
import ExportLineageAsImageFromSVG from './ExportLineageAsImageFromSVG';
import { LINEAGE_CANVAS_ID } from '../LineageVizRootSvg';

const LeftControlsDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 30px;
    margin-left: 48px;
    white-space: nowrap;

    @media (max-width: 768px) {
        gap: 8px;
        margin-left: 0;
    }
`;

const RightControlsDiv = styled.div`
    display: flex;
    flex-direction: row;
    margin-right: 48px;
    white-space: nowrap;

    @media (max-width: 768px) {
        margin-right: 0;
    }
`;

type Props = {
    fetchedEntities: { [x: string]: FetchedEntity };
    showExpandedTitles: boolean;
    setShowExpandedTitles: (showExpandedTitles: boolean) => void;
};

enum ExportAs {
    IMAGE = 'image',
    CSV = 'csv',
    SVG = 'svg',
}

export function LineageVizControls({ showExpandedTitles, setShowExpandedTitles, fetchedEntities }: Props) {
    const isHideSiblingMode = useIsSeparateSiblingsMode();
    const showColumns = useIsShowColumnsMode();

    const exportCsv = new ExportLineageAsCsv(fetchedEntities, showColumns);

    const exportImage = new ExportLineageAsImageFromSVG(`#${LINEAGE_CANVAS_ID}`);

    const handleMenuClick: MenuProps['onClick'] = async ({ key }) => {
        switch (key) {
            case ExportAs.CSV:
                // here we create the csv
                exportCsv.createCSV();

                // here we download the csv
                exportCsv.downloadCsv();
                break;

            case ExportAs.IMAGE:
                // here we init the element Path
                await exportImage.init();

                // here we download the png
                exportImage.downloadAsImage();
                break;

            default:
                // here we init the element Path
                await exportImage.init();

                // here we download the svg
                exportImage.downloadAsSvg();
                break;
        }
    };

    const items: MenuProps['items'] = [
        {
            label: 'as CSV',
            key: ExportAs.CSV,
        },
        {
            label: 'as Image',
            key: ExportAs.IMAGE,
        },
        {
            label: 'as Svg',
            key: ExportAs.SVG,
        },
    ];

    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    return (
        <>
            <LeftControlsDiv className="f-layout-wrap-xs f-justify-start-xs f-mb-3-xs">
                <LineageVizToggles
                    showExpandedTitles={showExpandedTitles}
                    setShowExpandedTitles={setShowExpandedTitles}
                />
            </LeftControlsDiv>
            <RightControlsDiv>
                <Space>
                    <Dropdown menu={menuProps} trigger={['click']}>
                        <Button>
                            Export
                            <CaretDownOutlined />
                        </Button>
                    </Dropdown>
                    <span>
                        <LineageVizTimeSelector isHideSiblingMode={isHideSiblingMode} showColumns={showColumns} />
                    </span>
                </Space>
            </RightControlsDiv>
        </>
    );
}
