import React from 'react';
import { Form, Input, Select, Typography } from 'antd';
import { PolicyType } from '../../../types.generated';

type Props = {
    policyType: string;
    setPolicyType: (type: PolicyType) => void;
    policyName: string;
    setPolicyName: (name: string) => void;
    policyDescription: string;
    setPolicyDescription: (description: string) => void;
};

export default function PolicyTypeForm({
    policyType,
    setPolicyType,
    policyName,
    setPolicyName,
    policyDescription,
    setPolicyDescription,
}: Props) {
    const updatePolicyName = (name: string) => {
        setPolicyName(name);
    };

    return (
        <Form layout="vertical">
            <Form.Item
                name="policyName"
                className="mb-4"
                label={
                    <Typography.Text>
                        <span>Name</span>
                        <span className="f-color-risk-very-high pl-1">*</span>
                    </Typography.Text>
                }
            >
                <Input
                    placeholder="Your policy name"
                    data-testid="policy-name"
                    value={policyName}
                    onChange={(event) => updatePolicyName(event.target.value)}
                />
                <Typography.Paragraph className="f-text-small-content mt-1 mb-0">
                    A name for your new policy.
                </Typography.Paragraph>
            </Form.Item>
            <Form.Item
                name="policyType"
                className="mb-4"
                label={
                    <Typography.Text>
                        <span>Type</span>
                        <span className="f-color-risk-very-high pl-1">*</span>
                    </Typography.Text>
                }
            >
                <Typography.Paragraph>The type of policy you would like to create.</Typography.Paragraph>
                <Select
                    data-testid="policy-type"
                    defaultValue={policyType}
                    onSelect={(value) => setPolicyType(value as PolicyType)}
                >
                    <Select.Option data-testid="platform" value={PolicyType.Platform}>
                        Platform
                    </Select.Option>
                    <Select.Option data-testid="metadata" value={PolicyType.Metadata}>
                        Metadata
                    </Select.Option>
                </Select>
                <Typography.Paragraph className="f-text-small-content mt-1 mb-0">
                    The type of policy you would like to create.
                </Typography.Paragraph>
                <Typography.Paragraph type="secondary" className="mt-3 mb-0">
                    The <b>Platform</b> policy type allows you to assign top-level Platform privileges to users. These
                    include managing users and groups, creating policies, viewing analytics dashboards and more.
                </Typography.Paragraph>
                <Typography.Paragraph type="secondary" className="mt-3 mb-0">
                    The <b>Metadata</b> policy type allows you to assign metadata privileges to users. These include the
                    ability to manipulate metadata like ownership, tags, documentation associated with Datasets, Charts,
                    Dashboards, & more.
                </Typography.Paragraph>
            </Form.Item>
            <Form.Item name="policyDescription" label="Description" className="mb-4">
                <Input
                    placeholder="Your policy description"
                    data-testid="policy-description"
                    value={policyDescription}
                    onChange={(event) => setPolicyDescription(event.target.value)}
                />
                <Typography.Paragraph className="f-text-small-content mt-1 mb-0">
                    An optional description for your new policy.
                </Typography.Paragraph>
            </Form.Item>
        </Form>
    );
}
