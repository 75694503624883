import { FineGrainedLineage } from '../../../types.generated';
import { EntityAndType, FetchedEntity } from '../types';

/**
 * Some terminology: If a user sees a node with an arrow toward this node, it means it is downstream in respect to the arrow coming from other node.
 * And that node is upstream of the current respected node.
 * Node -----> Node (upstream ---> downstream)
 * Node <----- Node (downstream ----> upstream)
 */

interface IColumns {
    downstream_field?: string;
    upstream_field?: string;
    downstream_urn?: string;
    upstream_urn?: string;
    downstream_name?: string;
    upstream_name?: string;
    downstream_type?: string;
    upstream_type?: string;
}

interface IEntities {
    is_view: boolean;
    downstream_urn: string;
    upstream_urn: string;
    downstream_name?: string;
    upstream_name?: string;
    downstream_type?: string;
    upstream_type?: string;
    upstream_description?: string;
    downstream_description?: string;
    upstream_owners?: string;
    downstream_owners?: string;
    upstream_tags?: string;
    downstream_tags?: string;
    upstream_terms?: string;
    downstream_terms?: string;
    upstream_domain?: string;
    downstream_domain?: string;
    upstream_platform?: string;
    downstream_platform?: string;
    upstream_container?: string;
    downstream_container?: string;
    upstream_entity_url?: string;
    downstream_entity_url?: string;
    'upstream_host/ip'?: string;
    'downstream_host/ip'?: string;
    columns: Array<IColumns>;
}

class ExportLineageAsCsv {
    // Fetched entities object from the API.
    fetchedEntities: Record<string, FetchedEntity> = {};

    // this flag add the column on csv or not
    showColumn: boolean | undefined = false;

    // Define which entity to trace by its URN.
    traceEntityNames: string[] = [];

    // Object to store entities after classification.
    Entities: Record<string, IEntities> = {};

    // This object is used to sort the column on csv
    private orderColumn: Record<string, string> = {
        upstream_urn: 'Upstream URN',
        downstream_urn: 'Downstream URN',
        upstream_type: 'Upstream Type',
        downstream_type: 'Downstream Type',
        upstream_description: 'Upstream Description',
        downstream_description: 'Downstream Description',
        upstream_owners: 'Upstream Owners',
        downstream_owners: 'Downstream Owners',
        upstream_tags: 'Upstream Tags',
        downstream_tags: 'Downstream Tags',
        upstream_terms: 'Upstream Terms',
        downstream_terms: 'Downstream Terms',
        upstream_domain: 'Upstream Domain',
        downstream_domain: 'Downstream Domain',
        upstream_platform: 'Upstream Platform',
        downstream_platform: 'Downstream Platform',
        upstream_container: 'Upstream Container',
        downstream_container: 'Downstream Container',
        upstream_entity_url: 'Upstream Entity URL',
        downstream_entity_url: 'Downstream Entity URL',
        'upstream_host/ip': 'Upstream Host/IP',
        'downstream_host/ip': 'Downstream Host/IP',
    };

    constructor(fetchedEntities: Record<string, FetchedEntity>, showColumn: boolean) {
        this.fetchedEntities = fetchedEntities;
        this.showColumn = showColumn;
        if (showColumn) {
            this.orderColumn = {
                ...this.orderColumn,
                upstream_name: 'Upstream Name',
                downstream_name: 'Downstream Name',
                upstream_field: 'Upstream Field',
                downstream_field: 'Downstream Field',
            };
        }
    }

    /**
     * This method is used to create a new entity.
     * @param {string} key - The key where we store the new entity.
     * @param {object} entity - The new entity object.
     * @returns {object} - Returns the newly created entity.
     */
    private createEntity(key: string, entity: IEntities) {
        this.Entities[key] = entity;
        return this.Entities[key];
    }

    /**
     * Removes HTML tags from a given text and returns the cleaned text.
     *
     * @function
     * @private
     * @param {string} text - The input text containing HTML tags.
     * @returns {string} The text with HTML tags removed.
     */
    private removeTagFromDescription = (text: string) => {
        const pattern = /<.*?>(.*?)<\/.*?>/;
        const match = text.match(pattern);
        return match ? match[1] : text;
    };

    /**
     * This method is used to generate the key.
     * @param {string} urn1 - The entity urn.
     * @param {string} urn2 - The entity urn.
     * @returns {string} - Returns a string with dot notation between the two urns.
     */
    private keyGenerator = (urn1: string, urn2: string) => {
        return `${urn1}.${urn2}`;
    };

    /**
     * This method is used to find the name from dot notation string.
     * @param {string} name - The dot notation string.
     * @returns {string} - Returns a string without dot notation.
     */
    private findName = (name: string) => {
        return name.replace(/.*\./, '');
    };

    /**
     * This method is used to find the name from URN.
     * @param {string} urn - The dot notation string.
     * @returns {string} - Returns a string without dot notation.
     */
    private findNameFromUrn = (urn: string) => {
        const regexPattern = /[^.]+(?=,[^,]+$)/;
        const match = urn.match(regexPattern);
        const result = match ? match[0] : '--';
        return result;
    };

    /**
     * This method is used to remove the extra quotation from the string.
     * @param {string} str - String with extra quotation.
     * @returns {string} - String without extra quotation.
     */
    private removeQuotation = (str: string | undefined = '') => {
        return str.replace(/^"|"$/g, '');
    };

    /**
     * This method is used to add the quotation on the string.
     * @param {string} str - String.
     * @returns {string} - add the quotation on string.
     */
    private addQuotation = (str: string | undefined = '') => {
        return `"${str?.trim?.() || '--'}"`;
    };

    /**
     * This method is used to create the entity route path
     * @param {string} urn - get the urn string.
     * @returns {string} - route path.
     */
    private createEntityUrl = (urn: string | undefined = '') => {
        return this.addQuotation(new URL(`dataset/${urn}/Schema`, window.location.origin).href);
    };

    /**
     * This method is used to enter the remaining details of the entity.
     * @param {string} sourceUrn - URN of the entity.
     * @param {string} destinationUrn - URN of the upstream entity.
     */
    private remainingFields(sourceUrn: string, destinationUrn: string, isView: boolean | undefined = false) {
        const {
            name: sourceName,
            subtype: sourceType,
            properties: sourceProperties = {},
            editableProperties: sourceEditProperties = {},
            ownership: sourceOwnership,
            globalTags: sourceTags,
            glossaryTerms: sourceTerms,
            domain: sourceDomain,
            platform: sourcePlatform,
            container: sourceContainer,
            customProperties: sourceCustomProperties = [],
        } = this.fetchedEntities[sourceUrn];
        const {
            name: destinationName,
            subtype: destinationType,
            properties: destinationProperties,
            editableProperties: destinationEditProperties,
            ownership: destinationOwnership,
            globalTags: destinationTags,
            glossaryTerms: destinationTerms,
            domain: destinationDomain,
            platform: destinationPlatform,
            container: destinationContainer,
            customProperties: destinationCustomProperties = [],
        } = this.fetchedEntities[destinationUrn];
        Object.assign(this.Entities[this.keyGenerator(sourceUrn, destinationUrn)], {
            is_view: isView,
            downstream_urn: this.addQuotation(sourceUrn) || '--',
            upstream_urn: this.addQuotation(destinationUrn) || '--',
            downstream_name: this.findName(sourceName) || '--',
            upstream_name: this.findName(destinationName) || '--',
            downstream_type: sourceType || '--',
            upstream_type: destinationType || '--',
            upstream_description: this.addQuotation(
                this.removeTagFromDescription(
                    sourceProperties?.description || sourceEditProperties?.description || '--',
                ),
            ),
            downstream_description: this.addQuotation(
                this.removeTagFromDescription(
                    destinationProperties?.description || destinationEditProperties?.description || '--',
                ),
            ),
            upstream_owners: this.addQuotation(
                sourceOwnership?.owners?.map(({ owner }) => (owner as any)?.username ?? '')?.join(','),
            ),
            downstream_owners: this.addQuotation(
                destinationOwnership?.owners?.map(({ owner }) => (owner as any)?.username ?? '')?.join(','),
            ),
            upstream_tags: this.addQuotation(sourceTags?.tags?.map(({ tag }) => tag?.name ?? '')?.join(',')),
            downstream_tags: this.addQuotation(destinationTags?.tags?.map(({ tag }) => tag?.name ?? '')?.join(',')),
            upstream_terms: this.addQuotation(sourceTerms?.terms?.map(({ term }) => term?.name ?? '')?.join(',')),
            downstream_terms: this.addQuotation(
                destinationTerms?.terms?.map(({ term }) => term?.name || '')?.join(','),
            ),
            upstream_domain: sourceDomain?.domain?.properties?.name || '--',
            downstream_domain: destinationDomain?.domain?.properties?.name || '--',
            upstream_platform: sourcePlatform?.name || '--',
            downstream_platform: destinationPlatform?.name || '--',
            upstream_container: this.addQuotation(sourceContainer?.urn),
            downstream_container: this.addQuotation(destinationContainer?.urn),
            upstream_entity_url: this.createEntityUrl(sourceUrn) || '--',
            downstream_entity_url: this.createEntityUrl(destinationUrn) || '--',
            'upstream_host/ip': sourceCustomProperties?.find(({ key }) => key === 'host/ip')?.value || '--',
            'downstream_host/ip': destinationCustomProperties?.find(({ key }) => key === 'host/ip')?.value || '--',
        });
    }

    /**
     * This method is used to check the relationship between entities if we missed out during the iteration.
     */
    private checkAnyEntityRelation() {
        Object.entries(this.Entities).forEach(([_, value]) => {
            const formatSourceUrn = this.removeQuotation(value.downstream_urn);
            const formatDestinationUrn = this.removeQuotation(value.upstream_urn);

            // here we check if we have both entity data then we will add all other condition on it
            if (this.fetchedEntities[formatSourceUrn] && this.fetchedEntities[formatDestinationUrn]) {
                this.remainingFields(formatSourceUrn, formatDestinationUrn, true);
            }
        });
    }

    /**
     * This method is used to create the entity on the 'Entities' object.
     * @param {object} entities - Object where entities are stored.
     * @param {object} currentEntity - Current entity object to be added.
     * @param {boolean} isDownChild - Flag indicating if it's a downChild or not.
     */
    private findEntity(
        entities: Array<EntityAndType>,
        currentEntity: FetchedEntity,
        is_view: boolean | undefined = false,
        isDownChild: boolean | undefined = false,
    ) {
        entities.forEach(({ entity }) => {
            // Here, we find the key.
            const key = isDownChild
                ? this.keyGenerator(entity.urn, currentEntity.urn)
                : this.keyGenerator(currentEntity.urn, entity.urn);

            // Here, we check if the entity does not exist, then we go ahead and create one.
            if (!this.Entities[key]) {
                const entityName = ((entity as { name: string })?.name ?? this.findNameFromUrn(entity.urn)) || '--';

                const entitySubType = (
                    (entity as { subTypes: { typeNames: Array<string> } })?.subTypes?.typeNames?.[0] || '--'
                ).trim();

                const [sourceUrn, destinationUrn, sourceName, destinationName] = isDownChild
                    ? [entity.urn, currentEntity.urn, entityName, currentEntity.name]
                    : [currentEntity.urn, entity.urn, currentEntity.name, entityName];

                const [sourceType, destinationType] = isDownChild
                    ? [entitySubType, (currentEntity.subtype || '--')?.trim()]
                    : [(currentEntity.subtype || '--')?.trim(), entitySubType];

                this.createEntity(key, {
                    is_view,
                    downstream_urn: `"${sourceUrn}"`,
                    upstream_urn: `"${destinationUrn}"`,
                    downstream_name: this.findName(sourceName),
                    upstream_name: this.findName(destinationName),
                    downstream_type: sourceType,
                    upstream_type: destinationType,
                    columns: [],
                });
            }
        });
    }

    /**
     * This method is used to enter the column between the relation between the current entity (downstream) and upstream child.
     * @param {Array<FineGrainedLineage>} grainedLineage - We get the array where the relation between the current entity (downstream) and upstream child is defined.
     */
    private fineGrainedLineages(grainedLineage: FineGrainedLineage[]) {
        grainedLineage.forEach(({ downstreams = [], upstreams = [] }) => {
            downstreams?.forEach(({ urn: dUrn = '', path: dPath = '' }) => {
                upstreams?.forEach(({ urn: uUrn = '', path: uPath = '' }) => {
                    // Here, we find the key. If the key is not available, then we create the entity, but is_view is set to false.
                    const key =
                        (this.Entities[this.keyGenerator(dUrn, uUrn)] && this.keyGenerator(dUrn, uUrn)) ||
                        (this.Entities[this.keyGenerator(uUrn, dUrn)] && this.keyGenerator(uUrn, dUrn)) ||
                        null;

                    if (!key) {
                        const entity = this.createEntity(this.keyGenerator(dUrn, uUrn), {
                            is_view: false,
                            downstream_urn: `"${dUrn}"`,
                            upstream_urn: `"${uUrn}"`,
                            columns: [],
                        });
                        entity.columns.push({
                            downstream_field: dPath.trim() || '--',
                            upstream_field: uPath.trim() || '--',
                        });
                    } else {
                        this.Entities[key].columns.push({
                            downstream_field: dPath.trim() || '--',
                            upstream_field: uPath.trim() || '--',
                        });
                    }

                    // Here, we check if the key is null and we find the uUrn in traceEntityNames. In that case, we will add the remaining fields, and is_view is set to true.
                    if (!key && this.traceEntityNames.includes(uUrn)) {
                        this.remainingFields(dUrn, uUrn, true);
                    }
                });
            });
        });
    }

    // This method is used to classify the data from the entities object according to CSV.
    private classifierData() {
        const excludedKeys = ['is_view', 'columns'];
        const columns = Object.entries(this.Entities)
            .filter(([_, value]) => value.is_view)
            .map(([_, value]) => {
                const remainingField = Object.fromEntries(
                    Object.entries(value).filter(([key]) => !excludedKeys.includes(key)),
                );
                return value.columns.length && this.showColumn
                    ? value.columns.map((obj) => ({ ...obj, ...remainingField }))
                    : [
                          {
                              ...(this.showColumn ? { upstream_field: '--', downstream_field: '--' } : {}),
                              ...remainingField,
                          },
                      ];
            })
            .flat();

        return columns.map((row) =>
            Object.keys(this.orderColumn)
                .map((key) => row[key])
                .filter(Boolean)
                .join(','),
        );
    }

    // This method is used to download the CSV file.
    downloadCsv() {
        // here we get the classified columns in string format
        const csvRows: string[] = this.classifierData();

        // Get the header row
        const header = Object.values(this.orderColumn);

        // Combine rows into a CSV string
        const csvData = [header.join(',')].concat(csvRows);

        // Create a Blob containing the CSV data
        const blob = new Blob([csvData.join('\n')], { type: 'text/csv' });

        // Create a download link
        const link = document.createElement('a');

        // attach the downloadable link
        link.href = URL.createObjectURL(blob);
        link.download = 'lineage_relations.csv';

        // Append the link to the document and trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up by removing the link from the document
        document.body.removeChild(link);
    }

    createCSV() {
        Object.entries(this.fetchedEntities).forEach(([key, entity]) => {
            // For downstream child.
            this.findEntity(entity.downstreamChildren || [], entity, entity.fullyFetched, true);

            // For upstream child.
            this.findEntity(entity.upstreamChildren || [], entity, entity.fullyFetched, false);

            // Here, we find the relationship column between the current entity and upstream child entity and also check column should show or not.
            if (this.showColumn) {
                this.fineGrainedLineages(entity?.fineGrainedLineages || []);
            }

            /// Put the entity's URN in traceEntityNames.
            this.traceEntityNames.push(key);
        });

        // Here, we check the relationship between any entity from the last iteration.
        this.checkAnyEntityRelation();
    }
}

export default ExportLineageAsCsv;
