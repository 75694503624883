import React, { useState } from 'react';

import EmptyGlossarySection from '../../glossary/EmptyGlossarySection';
import GlossaryEntitiesList from '../../glossary/GlossaryEntitiesList';
import GlossaryActions from './GlossaryActions';

import { EntityType, GlossaryNode, GlossaryTerm } from '../../../types.generated';
import { useEntityRegistry } from '../../useEntityRegistry';
import { sortGlossaryTerms } from '../glossaryTerm/utils';
import { useEntityData } from '../shared/EntityContext';
import { sortGlossaryNodes } from './utils';

function ChildrenTab() {
    const { entityData } = useEntityData();
    const entityRegistry = useEntityRegistry();
    const [selectedItems, setSelectedItems] = useState<string[]>([]);

    if (!entityData) return <></>;

    const childNodes = entityData?.children?.relationships
        .filter((child) => child.entity?.type === EntityType.GlossaryNode)
        .sort((nodeA, nodeB) => sortGlossaryNodes(entityRegistry, nodeA.entity, nodeB.entity))
        .map((child) => child.entity);
    const childTerms = entityData?.children?.relationships
        .filter((child) => child.entity?.type === EntityType.GlossaryTerm)
        .sort((termA, termB) => sortGlossaryTerms(entityRegistry, termA.entity, termB.entity))
        .map((child) => child.entity);

    const hasTermsOrNodes = !!childNodes?.length || !!childTerms?.length;

    if (hasTermsOrNodes) {
        return (
            <>
                <GlossaryActions
                    nodes={(childNodes as GlossaryNode[]) || []}
                    terms={(childTerms as GlossaryTerm[]) || []}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                />
                <GlossaryEntitiesList
                    nodes={(childNodes as GlossaryNode[]) || []}
                    terms={(childTerms as GlossaryTerm[]) || []}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                />
            </>
        );
    }

    return <EmptyGlossarySection description="No Terms or Term Groups" />;
}

export default ChildrenTab;
