import { Result } from 'antd';
import React from 'react';

export const UnauthorizedPage = (props: { width?: string }) => {
    return (
        <>
            <Result
                status="403"
                style={{ width: props?.width }}
                title="Unauthorized"
                subTitle="Sorry, you are not authorized to access this page."
            />
        </>
    );
};
