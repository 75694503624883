import React from 'react';
import { List } from 'antd';
import styled from 'styled-components';
import { useEntityRegistry } from '../../../../../useEntityRegistry';
import { PreviewType } from '../../../../Entity';
import { EntityType } from '../../../../../../types.generated';

const StyledList = styled(List)`
    .ant-list-items > .ant-list-item {
        padding: 24px;
    }
    > .ant-list-header {
        border-bottom: none;
    }
` as typeof List;

const StyledListItem = styled(List.Item)`
    padding-top: 20px;
`;

type EntityListProps = {
    type: EntityType;
    entities: Array<any>;
    title?: string;
};

export const EntityList = ({ type, entities, title }: EntityListProps) => {
    const entityRegistry = useEntityRegistry();
    return (
        <StyledList
            dataSource={entities}
            header={
                <div className="f-text-title f-text-normal f-color-dark-black-s80 px-3">
                    {title || `${entities.length || 0} ${entityRegistry.getCollectionName(type)}`}
                </div>
            }
            renderItem={(item) => (
                <StyledListItem>{entityRegistry.renderPreview(type, PreviewType.PREVIEW, item)}</StyledListItem>
            )}
        />
    );
};
