import React from 'react';
import { Col, Row } from 'antd';
import { LegendOrdinal, LegendItem, LegendLabel } from '@vx/legend';
import { ScaleOrdinal } from 'd3-scale/src/ordinal';
import styled from 'styled-components';

const legendGlyphSize = 10;

type Props = {
    ordinalScale: ScaleOrdinal<string, string>;
};

const LegendRow = styled(Row)`
    width: 100%;
`;

const LegentText = styled(LegendLabel)`
    width: calc(100% - 10px);
    display: block !important;
`;

export default function Legend({ ordinalScale }: Props) {
    return (
        <LegendRow className="px-3">
            <LegendOrdinal scale={ordinalScale} labelFormat={(d: any) => d}>
                {(labels) => {
                    return labels.map((label) => (
                        <Col span={8}>
                            <LegendItem key={`legend-quantile-${label}`}>
                                <svg width={legendGlyphSize} height={legendGlyphSize} style={{ margin: '2px 0' }}>
                                    <circle
                                        fill={label.value}
                                        r={legendGlyphSize / 2}
                                        cx={legendGlyphSize / 2}
                                        cy={legendGlyphSize / 2}
                                    />
                                </svg>
                                <LegentText
                                    margin="0"
                                    align="left"
                                    className="f-text-medium-content f-color-dark-black-s50 f-text-capitalize truncate pl-2"
                                >
                                    {label.text}
                                </LegentText>
                            </LegendItem>
                        </Col>
                    ));
                }}
            </LegendOrdinal>
        </LegendRow>
    );
}
