import React, { useState } from 'react';
import { Button, Form, message, Modal, Select, Tag } from 'antd';

import { useAddGovernanceFlagsAspectMutation } from '../../../../graphql/mutations.generated';

type Props = {
    field?: any;
    entityData: any;
    onClose: () => void;
    refetch?: () => void;
};

/**
 * This component is used to add or remove governance flags for the entity profile page header section
 * @param entityData - entity data
 * @param onClose - function to close the modal
 * @param refetch - function to refetch the data
 * @param field - field data
 */
export const AddOrRemoveGovernanceFlagModal = ({ entityData, onClose, refetch, field }: Props) => {
    const [updateAspectGovernance] = useAddGovernanceFlagsAspectMutation();
    const [isFormValid, setIsFormValid] = useState(true);

    const [form] = Form.useForm();

    // Close the modal and reset the form
    const handleClose = () => {
        form.resetFields();
        onClose();
    };

    // Function to handle the form submit and update the governance flag
    const handleOk = async (formData: any) => {
        message.loading({ content: 'Adding Flag' });
        const payload = {
            names: formData.names,
        };

        if (field) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            payload['subResource'] = field.fieldPath;
        }

        try {
            await updateAspectGovernance({
                variables: {
                    urn: entityData.urn,
                    input: payload,
                },
            });
            message.destroy();
            message.success({ content: 'Flag added successfully', duration: 2 });
        } catch (e: unknown) {
            message.destroy();
        }
        refetch?.();
        handleClose();
    };

    // Function to handle the tag close event and remove the tag from the form
    const handleTagClose = (valueToRemove) => {
        const selectedValues = form.getFieldValue('names');
        const names = selectedValues.filter((value) => value !== valueToRemove);

        if (names.length === 0) {
            setIsFormValid(true);
        }
        form.setFieldsValue({ names });
    };

    return (
        <Modal
            title="Add Governance Flag"
            visible
            onCancel={handleClose}
            keyboard
            centered
            footer={
                <>
                    <Button
                        form="updateGovernanceFlagForm"
                        key="submit"
                        htmlType="submit"
                        type="primary"
                        disabled={isFormValid}
                    >
                        Add
                    </Button>
                    <Button onClick={handleClose}>Cancel</Button>
                </>
            }
        >
            <Form
                form={form}
                name="updateGovernanceFlagForm"
                onFinish={handleOk}
                layout="vertical"
                onFieldsChange={() => {
                    const names = form.getFieldValue('names');
                    setIsFormValid(names.length === 0);
                }}
            >
                <Form.Item name="names" label="Select Flag" required>
                    <Select
                        mode="multiple"
                        placeholder="Please select"
                        options={[
                            { value: 'CDE', label: 'CDE' },
                            { value: 'PII', label: 'PII' },
                        ]}
                        tagRender={(props: any) => {
                            const { label, closable, value } = props;
                            return (
                                <Tag
                                    key={value}
                                    closable={closable}
                                    onClose={() => handleTagClose(value)}
                                    style={{ marginRight: 3 }}
                                >
                                    {label}
                                </Tag>
                            );
                        }}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};
