import React, { useState } from 'react';
import { Row, Alert, Input, Select, Divider } from 'antd';
import styled from 'styled-components';
import { SearchOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { ChartGroup } from './ChartGroup';
import { useGetAnalyticsChartsQuery, useGetMetadataAnalyticsChartsQuery } from '../../../graphql/analytics.generated';
import { useGetHighlightsQuery } from '../../../graphql/highlights.generated';
import { Highlight } from './Highlight';
import { Message } from '../../shared/Message';
import { useListDomainsQuery } from '../../../graphql/domain.generated';
import filterSearchQuery from '../../search/utils/filterSearchQuery';
// TODO: Check in the repository if this import is needed
// import { ANTD_GRAY } from '../../entity/shared/constants';
import { useUserContext } from '../../context/useUserContext';

// const IS_DEV = false

const MetadataAnalyticsInput = styled.div`
    display: flex;
    margin-bottom: 16px;
`;

const MetadataAnalyticsPlaceholder = styled.div`
    background: #f4f6f8;
    height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const DomainSelect = styled(Select)`
    &&& {
        margin-right: 8px;
        width: calc(100% / 3);

        .ant-select-selector {
            height: 48px;
            background-color: #ffffff;

            .ant-select-selection-search-input {
                height: 100%;
            }

            .ant-select-selection-placeholder {
                display: flex;
                align-items: center;
                color: #8f9094;
            }

            .ant-select-selection-item {
                display: flex;
                align-items: center;
                color: #4c4e54;
            }
        }
    }

    @media (max-width: 768px) {
        margin-right: 0 !important;
        margin-bottom: 8px;
    }
`;

const StyledSearchBar = styled(Input)`
    &&& {
        height: 48px;
        border: none;
        margin-left: 8px;
        border-radius: 0;
        width: calc((100% / 3) * 2);

        .ant-input {
            height: auto;
            background-color: transparent;
        }

        .anticon-search svg {
            width: 18px;
            height: 18px;
        }
    }

    @media (max-width: 768px) {
        margin-left: 0 !important;
    }
`;

export const AnalyticsPage = () => {
    const me = useUserContext();
    const canManageDomains = me?.platformPrivileges?.createDomains;
    const { data: chartData, loading: chartLoading, error: chartError } = useGetAnalyticsChartsQuery();
    const { data: highlightData, loading: highlightLoading, error: highlightError } = useGetHighlightsQuery();
    const {
        loading: domainLoading,
        error: domainError,
        data: domainData,
    } = useListDomainsQuery({
        skip: !canManageDomains,
        variables: {
            input: {
                start: 0,
                count: 1000,
            },
        },
        fetchPolicy: 'no-cache',
    });
    const [domain, setDomain] = useState('ALL');
    const [stagedQuery, setStagedQuery] = useState('');
    const [query, setQuery] = useState('');

    const onDomainChange = (inputDomain) => setDomain(inputDomain);
    const onStagedQueryChange = (inputQuery) => setStagedQuery(inputQuery);
    const {
        loading: metadataAnalyticsLoading,
        error: metadataAnalyticsError,
        data: metadataAnalyticsData,
    } = useGetMetadataAnalyticsChartsQuery({
        variables: {
            input: {
                entityType: null,
                domain,
                query,
            },
        },
        skip: domain === '' && query === '',
    });

    const isLoading = highlightLoading || chartLoading || domainLoading || metadataAnalyticsLoading;
    return (
        <>
            <Row gutter={[8, 0]} className="my-2">
                {isLoading && <Message type="loading" content="Loading..." style={{ marginTop: '10%' }} />}
                {highlightError && (
                    <Alert type="error" message={highlightError?.message || 'Highlights failed to load'} />
                )}
                {highlightData?.getHighlights?.map((highlight) => (
                    <Highlight highlight={highlight} shortenValue />
                ))}
            </Row>
            <>
                {chartError && (
                    <Alert type="error" message={metadataAnalyticsError?.message || 'Charts failed to load'} />
                )}
                {chartData?.getAnalyticsCharts
                    ?.filter((chartGroup) => chartGroup.groupId === 'GlobalMetadataAnalytics')
                    .map((chartGroup) => (
                        <ChartGroup chartGroup={chartGroup} />
                    ))}
            </>
            <>
                {domainError && (
                    <Alert type="error" message={metadataAnalyticsError?.message || 'Domains failed to load'} />
                )}
                {!chartLoading && (
                    <>
                        <Divider />
                        <MetadataAnalyticsInput className="f-layout-wrap-xs">
                            <DomainSelect
                                showSearch
                                placeholder="All"
                                onChange={onDomainChange}
                                className="f-width-100-xs"
                                filterOption={(input, option) =>
                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Select.Option value="ALL">All</Select.Option>
                                {domainData?.listDomains?.domains.map((domainChoice) => (
                                    <Select.Option value={domainChoice.urn}>
                                        {domainChoice?.properties?.name}
                                    </Select.Option>
                                ))}
                            </DomainSelect>
                            <StyledSearchBar
                                placeholder="Search"
                                value={stagedQuery}
                                className="f-width-100-xs"
                                data-testid="analytics-search-input"
                                onPressEnter={(e) => {
                                    e.stopPropagation();
                                    setQuery(filterSearchQuery(stagedQuery || ''));
                                }}
                                onChange={(e) => onStagedQueryChange(e.target.value)}
                                prefix={
                                    <SearchOutlined onClick={() => setQuery(filterSearchQuery(stagedQuery || ''))} />
                                }
                            />
                        </MetadataAnalyticsInput>
                    </>
                )}
            </>
            <>
                {metadataAnalyticsError && (
                    <Alert type="error" message={metadataAnalyticsError?.message || 'Charts failed to load'} />
                )}
                {domain === '' && query === ''
                    ? !chartLoading && (
                          <MetadataAnalyticsPlaceholder>
                              <InfoCircleOutlined className="f-icon-size-md f-color-dark-black-s50" />
                              <div className="f-text-big-content f-color-dark-black-s50 mt-2">
                                  Please specify domain or query to get granular results
                              </div>
                          </MetadataAnalyticsPlaceholder>
                      )
                    : metadataAnalyticsData?.getMetadataAnalyticsCharts?.map((chartGroup) => (
                          <ChartGroup chartGroup={chartGroup} />
                      ))}
            </>
            <>
                {chartError && <Alert type="error" message={chartError?.message || 'Charts failed to load'} />}
                {!chartLoading &&
                    chartData?.getAnalyticsCharts
                        ?.filter((chartGroup) => chartGroup.groupId === 'DataHubUsageAnalytics')
                        .map((chartGroup) => (
                            <>
                                <Divider />
                                <ChartGroup chartGroup={chartGroup} />
                            </>
                        ))}
            </>
        </>
    );
};
