import React from 'react';
import { Typography } from 'antd';
import { OnboardingStep } from '../OnboardingStep';

export const POLICIES_INTRO_ID = 'policies-intro';
export const POLICIES_CREATE_POLICY_ID = 'policies-create-policy';

export const PoliciesOnboardingConfig: OnboardingStep[] = [
    {
        id: POLICIES_INTRO_ID,
        title: 'Policies',
        content: (
            <Typography.Paragraph>
                <p>
                    Welcome to Data Catalog <strong>Policies</strong>!
                </p>
                <p>
                    In most cases, <strong>Roles</strong> are the best option for granting privileges to Data Catalog
                    users.
                </p>
                <p>
                    When more fine-grained control over user and group permissions is required, then{' '}
                    <strong>Policies</strong> will do the trick.
                </p>
                <p>
                    {/* Removed the anchor tag for now */}
                    Learn more about <strong>Policies</strong> <span>here.</span>
                </p>
            </Typography.Paragraph>
        ),
    },
    {
        id: POLICIES_CREATE_POLICY_ID,
        selector: `#${POLICIES_CREATE_POLICY_ID}`,
        title: 'Create a new Policy',
        content: (
            <Typography.Paragraph>
                <p>
                    Click here to create a new <strong>Policy</strong>.
                </p>
            </Typography.Paragraph>
        ),
    },
];
