import { CheckOutlined, CopyOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { TooltipPlacement } from 'antd/lib/tooltip';

interface CopyUrnProps {
    urn: string;
    isActive: boolean;
    onClick: () => void;
    tooltipPlacement?: TooltipPlacement; // Change tooltipPlacement prop type
}

const ButtonWrapper = styled(Button)`
    width: auto;
`;

export default function CopyUrn({ urn, isActive, onClick, tooltipPlacement = 'top' }: CopyUrnProps) {
    if (navigator.clipboard) {
        return (
            <Tooltip
                placement={tooltipPlacement}
                title="Copy URN. An URN uniquely identifies an entity on DataCatalog."
            >
                <ButtonWrapper
                    icon={isActive ? <CheckOutlined /> : <CopyOutlined />}
                    onClick={() => {
                        navigator.clipboard.writeText(urn);
                        onClick();
                    }}
                />
            </Tooltip>
        );
    }

    return null;
}
