import React, { useState, useEffect } from 'react';
import { Button, Dropdown, Menu, message } from 'antd';
import { FormOutlined, MoreOutlined } from '@ant-design/icons';
import styled from 'styled-components/macro';
import { EntityType, AndFilterInput } from '../../../../../../types.generated';
import DownloadAsCsvButton from './DownloadAsCsvButton';
import DownloadAsCsvModal from './DownloadAsCsvModal';
import { useCheckDownloadTypeLazyQuery } from '../../../../../../graphql/search.generated';
import { DownloadSearchResultsInput, DownloadSearchResults } from '../../../../../search/utils/types';

const SEARCH_PAGE_SIZE_FOR_DOWNLOAD = 500;

const EMAIL = 'email';
const FILE = 'file';

const MenuIcon = styled(MoreOutlined)`
    font-size: 20px;
    height: 20px;
`;

const SelectButton = styled(Button)`
    font-size: 12px;
    padding-left: 12px;
    padding-right: 12px;
`;

const MenuItem = styled(Menu.Item)`
    padding: 0px;
`;

type Props = {
    filters: AndFilterInput[];
    query: string;
    viewUrn?: string;
    totalResults?: number;
    entityFilters: EntityType[];
    downloadSearchResults: (input: DownloadSearchResultsInput) => Promise<DownloadSearchResults | null | undefined>;
    setShowSelectMode?: (showSelectMode: boolean) => any;
};

// currently only contains Download As Csv but will be extended to contain other actions as well
export default function SearchExtendedMenu({
    downloadSearchResults,
    entityFilters,
    filters,
    query,
    viewUrn,
    totalResults,
    setShowSelectMode,
}: Props) {
    const [isDownloadingCsv, setIsDownloadingCsv] = useState(false);
    const [showDownloadAsCsvModal, setShowDownloadAsCsvModal] = useState(false);
    const [checkDownloadTypeQuery, { data: downloadType }] = useCheckDownloadTypeLazyQuery();

    const handleDownloadAsCsv = () => {
        /**
         * Refetches a GraphQL query to check the download type.
         * This function is used to verify the download type of a specific resource
         * by refetching the relevant GraphQL query with updated variables.
         */

        checkDownloadTypeQuery({
            variables: {
                input: {
                    windowLocation: window.location.origin,
                    types: entityFilters,
                    query,
                    start: 0,
                    count: SEARCH_PAGE_SIZE_FOR_DOWNLOAD,
                    orFilters: filters,
                },
            },
        });
    };

    useEffect(() => {
        const { checkDownloadType }: any = downloadType || {};
        if (checkDownloadType) {
            switch (checkDownloadType?.type) {
                case EMAIL:
                    message.success('Started Processing, You will receive an email shortly');
                    break;
                case FILE:
                    setShowDownloadAsCsvModal(true);
                    break;
                default:
                    message.error('Something went wrong');
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [downloadType]);

    const menu = (
        <Menu>
            <MenuItem key="0" onClick={handleDownloadAsCsv}>
                <DownloadAsCsvButton isDownloadingCsv={isDownloadingCsv} />
            </MenuItem>
            {setShowSelectMode && (
                <MenuItem key="1" onClick={() => setShowSelectMode(true)}>
                    <SelectButton type="text">
                        <FormOutlined />
                        Edit
                    </SelectButton>
                </MenuItem>
            )}
        </Menu>
    );

    return (
        <>
            <DownloadAsCsvModal
                downloadType={downloadType}
                downloadSearchResults={downloadSearchResults}
                filters={filters}
                query={query}
                viewUrn={viewUrn}
                setIsDownloadingCsv={setIsDownloadingCsv}
                showDownloadAsCsvModal={showDownloadAsCsvModal}
                setShowDownloadAsCsvModal={setShowDownloadAsCsvModal}
                totalResults={totalResults}
            />
            <Dropdown overlay={menu} trigger={['click']}>
                <MenuIcon data-testid="three-dot-menu" />
            </Dropdown>
        </>
    );
}
