import React, { useState } from 'react';
import { Typography, Row, Col } from 'antd';
// import styled from 'styled-components';

import { SearchBar } from './SearchBar';
import { AutoCompleteResultForEntity, EntityType } from '../../types.generated';
import EntityRegistry from '../entity/EntityRegistry';
import { HeaderLinks } from '../shared/admin/HeaderLinks';
// import { useAppConfig } from '../useAppConfig';
// import { ViewSelect } from '../entity/view/select/ViewSelect';

const styles = {
    pageHeader: { marginBottom: 0 },
    pageHeaderRow: { marginBottom: '24px' },
};

// const ViewSelectContainer = styled.span`
//     margin-right: 14px;
// `;

type Props = {
    initialQuery: string;
    title: string;
    placeholderText: string;
    suggestions: Array<AutoCompleteResultForEntity>;
    onSearch: (query: string, type?: EntityType) => void;
    onQueryChange: (query: string) => void;
    entityRegistry: EntityRegistry;
};

/**
 * A header containing a Logo, Search Bar view, & an account management dropdown.
 */
export const SearchHeader = ({
    initialQuery,
    title,
    placeholderText,
    suggestions,
    onSearch,
    onQueryChange,
    entityRegistry,
}: Props) => {
    const [isSearchBarFocused, setIsSearchBarFocused] = useState(false);
    // const appConfig = useAppConfig();
    // const viewsEnabled = appConfig.config?.viewsConfig?.enabled;

    return (
        <>
            <Row justify="space-between" align="middle" style={styles.pageHeaderRow} className="f-justify-center-xs">
                <Col>
                    <Typography.Text className="page-heading f-text-capitalize" style={styles.pageHeader}>
                        {title}
                    </Typography.Text>
                </Col>
                <Col>
                    {/* {viewsEnabled && (
                        <ViewSelectContainer>
                            <ViewSelect />
                        </ViewSelectContainer>
                    )} */}
                    <HeaderLinks areLinksHidden={isSearchBarFocused} />
                </Col>
            </Row>
            <Row justify="center" align="middle">
                <Col xs={24} lg={12}>
                    <SearchBar
                        combineSiblings
                        showQuickFilters
                        onSearch={onSearch}
                        suggestions={suggestions}
                        initialQuery={initialQuery}
                        onQueryChange={onQueryChange}
                        entityRegistry={entityRegistry}
                        //  viewsEnabled={viewsEnabled}
                        placeholderText={placeholderText}
                        setIsSearchBarFocused={setIsSearchBarFocused}
                    />
                </Col>
            </Row>
        </>
    );
};
