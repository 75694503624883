import React from 'react';
import { Typography, Card, Row, Col } from 'antd';
import {
    FacetFilterInput,
    RecommendationModule as RecommendationModuleType,
    ScenarioType,
} from '../../types.generated';
import { useListRecommendationsQuery } from '../../graphql/recommendations.generated';
import { RecommendationModule } from '../recommendations/RecommendationModule';

type Props = {
    userUrn: string;
    query: string;
    filters: Array<FacetFilterInput>;
};

export const SearchResultsRecommendations = ({ userUrn, query, filters }: Props) => {
    const scenario = ScenarioType.SearchResults;
    const { data } = useListRecommendationsQuery({
        variables: {
            input: {
                userUrn,
                requestContext: {
                    scenario,
                    searchRequestContext: {
                        query,
                        filters,
                    },
                },
                limit: 3,
            },
        },
    });
    const recommendationModules = data?.listRecommendations?.modules;
    return (
        <>
            {recommendationModules && !!recommendationModules.length && (
                <div data-testid="recommendation-container-id">
                    <Typography.Title className="f-text-big-content f-color-dark-black-s80 mb-3">
                        More you may be interested in
                    </Typography.Title>
                    <Row gutter={[8, 8]}>
                        {recommendationModules &&
                            recommendationModules.map((module) => (
                                <Col xs={24} lg={12}>
                                    <Card title={module.title}>
                                        <RecommendationModule
                                            module={module as RecommendationModuleType}
                                            scenarioType={scenario}
                                            showTitle={false}
                                        />
                                    </Card>
                                </Col>
                            ))}
                    </Row>
                </div>
            )}
        </>
    );
};
