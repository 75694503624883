import React from 'react';
import { Typography } from 'antd';
import { OnboardingStep } from '../OnboardingStep';

export const GROUPS_INTRO_ID = 'groups-intro';
export const GROUPS_CREATE_GROUP_ID = 'groups-create-group';

export const GroupsOnboardingConfig: OnboardingStep[] = [
    {
        id: GROUPS_INTRO_ID,
        title: 'Groups',
        content: (
            <Typography.Paragraph>
                <p>
                    Welcome to Data Catalog <strong>Groups</strong>!
                </p>
                <p>
                    <strong>Groups</strong> are collections of users which can be used to assign ownership to assets and
                    manage access.
                </p>
                <p>
                    <strong>Groups</strong> can be created natively within Data Catalog, or synced from your Identity
                    Provider.
                </p>
                <p>
                    {/* Removed the anchor tag for now */}
                    Learn more about <strong>Groups</strong> <span>here.</span>
                </p>
            </Typography.Paragraph>
        ),
    },
    {
        id: GROUPS_CREATE_GROUP_ID,
        selector: `#${GROUPS_CREATE_GROUP_ID}`,
        title: 'Create a new Group',
        content: (
            <Typography.Paragraph>
                <p>
                    Click here to create a new <strong>Group</strong>.
                </p>
            </Typography.Paragraph>
        ),
    },
];
