/* eslint-disable */
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type AssetAuditRetrievalQueryVariables = Types.Exact<{
    input: Types.AssetAuditSearch;
}>;

export type AssetAuditRetrievalQuery = { __typename?: 'Query' } & {
    assetAuditRetrieval?: Types.Maybe<
        { __typename?: 'AssetAuditRetrievalResult' } & Pick<Types.AssetAuditRetrievalResult, 'totalResults'> & {
                result: Array<
                    { __typename?: 'SingleAssetAuditResult' } & Pick<
                        Types.SingleAssetAuditResult,
                        'time' | 'activity' | 'attribute' | 'user' | 'message' | 'oldValue' | 'newValue'
                    >
                >;
            }
    >;
};

export const AssetAuditRetrievalDocument = gql`
    query assetAuditRetrieval($input: AssetAuditSearch!) {
        assetAuditRetrieval(input: $input) {
            result {
                time
                activity
                attribute
                user
                message
                oldValue
                newValue
            }
            totalResults
        }
    }
`;

/**
 * __useAssetAuditRetrievalQuery__
 *
 * To run a query within a React component, call `useAssetAuditRetrievalQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetAuditRetrievalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetAuditRetrievalQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssetAuditRetrievalQuery(
    baseOptions: Apollo.QueryHookOptions<AssetAuditRetrievalQuery, AssetAuditRetrievalQueryVariables>,
) {
    return Apollo.useQuery<AssetAuditRetrievalQuery, AssetAuditRetrievalQueryVariables>(
        AssetAuditRetrievalDocument,
        baseOptions,
    );
}
export function useAssetAuditRetrievalLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<AssetAuditRetrievalQuery, AssetAuditRetrievalQueryVariables>,
) {
    return Apollo.useLazyQuery<AssetAuditRetrievalQuery, AssetAuditRetrievalQueryVariables>(
        AssetAuditRetrievalDocument,
        baseOptions,
    );
}
export type AssetAuditRetrievalQueryHookResult = ReturnType<typeof useAssetAuditRetrievalQuery>;
export type AssetAuditRetrievalLazyQueryHookResult = ReturnType<typeof useAssetAuditRetrievalLazyQuery>;
export type AssetAuditRetrievalQueryResult = Apollo.QueryResult<
    AssetAuditRetrievalQuery,
    AssetAuditRetrievalQueryVariables
>;
