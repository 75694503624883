import React, { CSSProperties } from 'react';
import { Pagination, Typography } from 'antd';
import styled from 'styled-components/macro';
import { Message } from '../shared/Message';
import {
    Entity,
    EntityType,
    FacetFilterInput,
    FacetMetadata,
    MatchedField,
    SearchSuggestion,
} from '../../types.generated';
import { SearchCfg } from '../../conf';
import { SearchResultsRecommendations } from './SearchResultsRecommendations';
import SearchExtendedMenu from '../entity/shared/components/styled/search/SearchExtendedMenu';
import { SearchSelectBar } from '../entity/shared/components/styled/search/SearchSelectBar';
import { SearchResultList } from './SearchResultList';
import { isListSubset } from '../entity/shared/utils';
import TabToolbar from '../entity/shared/components/styled/TabToolbar';
import { EntityAndType } from '../entity/shared/types';
import { ErrorSection } from '../shared/error/ErrorSection';
import { UnionType } from './utils/constants';
import { SearchFiltersSection } from './SearchFiltersSection';
import { generateOrFilters } from './utils/generateOrFilters';
import { useUserContext } from '../context/useUserContext';
import { DownloadSearchResults, DownloadSearchResultsInput } from './utils/types';
import { combineSiblingsInSearchResults } from './utils/combineSiblingsInSearchResults';
import SearchQuerySuggester from './suggestions/SearchQuerySugggester';

const SearchBody = styled.div`
    display: flex;
    min-height: 100%;
    flex: 1;
    margin-top: 24px;
    overflow: auto;
`;

const ResultContainer = styled.div`
    flex: 1;

    .ant-list {
        height: 100%;
    }
`;

const PaginationControlContainer = styled.div`
    padding-top: 16px;
    padding-bottom: 16px;
    text-align: right;

    @media (max-width: 768px) {
        text-align: center;
    }
`;

const PaginationInfoContainer = styled.div`
    display: flex;
    margin-top: 15px;
    align-items: center;
    justify-content: space-between;
`;

const SearchResultsRecommendationsContainer = styled.div`
    margin-top: 16px;
`;

const StyledTabToolbar = styled(TabToolbar)`
    padding-left: 32px;
    padding-right: 32px;
`;

const SearchMenuContainer = styled.div``;

interface Props {
    unionType?: UnionType;
    query: string;
    viewUrn?: string;
    page: number;
    searchResponse?: {
        start: number;
        count: number;
        total: number;
        searchResults?: {
            entity: Entity;
            matchedFields: MatchedField[];
        }[];
    } | null;
    facets?: Array<FacetMetadata> | null;
    selectedFilters: Array<FacetFilterInput>;
    loading: boolean;
    error: any;
    onChangeFilters: (filters: Array<FacetFilterInput>) => void;
    onChangeUnionType: (unionType: UnionType) => void;
    onChangePage: (page: number) => void;
    downloadSearchResults: (input: DownloadSearchResultsInput) => Promise<DownloadSearchResults | null | undefined>;
    numResultsPerPage: number;
    entityFilters: EntityType[];
    setNumResultsPerPage: (numResults: number) => void;
    isSelectMode: boolean;
    selectedEntities: EntityAndType[];
    suggestions: SearchSuggestion[];
    setSelectedEntities: (entities: EntityAndType[]) => void;
    setIsSelectMode: (showSelectMode: boolean) => any;
    onChangeSelectAll: (selected: boolean) => void;
    refetch: () => void;
}

export const SearchResults = ({
    unionType = UnionType.AND,
    query,
    viewUrn,
    page,
    searchResponse,
    facets,
    selectedFilters,
    loading,
    error,
    onChangeUnionType,
    onChangeFilters,
    onChangePage,
    downloadSearchResults,
    entityFilters,
    numResultsPerPage,
    setNumResultsPerPage,
    isSelectMode,
    selectedEntities,
    suggestions,
    setIsSelectMode,
    setSelectedEntities,
    onChangeSelectAll,
    refetch,
}: Props) => {
    const pageStart = searchResponse?.start || 0;
    const pageSize = searchResponse?.count || 0;
    const totalResults = searchResponse?.total || 0;
    const lastResultIndex = pageStart + pageSize > totalResults ? totalResults : pageStart + pageSize;
    const authenticatedUserUrn = useUserContext().user?.urn;
    const combinedSiblingSearchResults = combineSiblingsInSearchResults(searchResponse?.searchResults);

    const searchResultUrns = combinedSiblingSearchResults.map((result) => result.entity.urn) || [];
    const selectedEntityUrns = selectedEntities.map((entity) => entity.urn);

    const loadingMessageStyle: CSSProperties = {
        transform: 'translate(-50%, -50%)',
        position: 'absolute',
        top: '50vh',
        left: '50%',
    };

    return (
        <>
            {loading && <Message type="loading" content="Loading..." style={loadingMessageStyle} />}

            <div>
                <PaginationInfoContainer>
                    <Typography.Paragraph className="mb-0">
                        Showing{' '}
                        <b>
                            {lastResultIndex > 0 ? (page - 1) * pageSize + 1 : 0} - {lastResultIndex}
                        </b>{' '}
                        of <b>{totalResults}</b> results
                    </Typography.Paragraph>
                    <SearchMenuContainer>
                        <SearchExtendedMenu
                            downloadSearchResults={downloadSearchResults}
                            filters={generateOrFilters(unionType, selectedFilters)}
                            entityFilters={entityFilters}
                            query={query}
                            viewUrn={viewUrn}
                            setShowSelectMode={setIsSelectMode}
                            totalResults={totalResults}
                        />
                    </SearchMenuContainer>
                </PaginationInfoContainer>
                <SearchBody>
                    {false && (
                        <div>
                            <SearchFiltersSection
                                filters={facets}
                                selectedFilters={selectedFilters}
                                unionType={unionType}
                                loading={loading}
                                onChangeFilters={onChangeFilters}
                                onChangeUnionType={onChangeUnionType}
                            />
                        </div>
                    )}
                    <ResultContainer>
                        {isSelectMode && (
                            <StyledTabToolbar className="f-layout-wrap-xs f-px-3-xs">
                                <SearchSelectBar
                                    isSelectAll={
                                        selectedEntities.length > 0 &&
                                        isListSubset(searchResultUrns, selectedEntityUrns)
                                    }
                                    selectedEntities={selectedEntities}
                                    onChangeSelectAll={onChangeSelectAll}
                                    onCancel={() => setIsSelectMode(false)}
                                    refetch={refetch}
                                />
                            </StyledTabToolbar>
                        )}
                        {(error && <ErrorSection />) ||
                            (!loading && (
                                <>
                                    {totalResults > 0 && <SearchQuerySuggester suggestions={suggestions} />}
                                    <SearchResultList
                                        query={query}
                                        searchResults={combinedSiblingSearchResults}
                                        totalResultCount={totalResults}
                                        isSelectMode={isSelectMode}
                                        selectedEntities={selectedEntities}
                                        setSelectedEntities={setSelectedEntities}
                                        suggestions={suggestions}
                                    />
                                </>
                            ))}
                    </ResultContainer>
                </SearchBody>
                {!loading && (
                    <>
                        <PaginationControlContainer>
                            <Pagination
                                current={page}
                                pageSize={numResultsPerPage}
                                total={totalResults}
                                showLessItems
                                onChange={onChangePage}
                                showSizeChanger={totalResults > SearchCfg.RESULTS_PER_PAGE}
                                onShowSizeChange={(_currNum, newNum) => setNumResultsPerPage(newNum)}
                                pageSizeOptions={['10', '20', '50', '100']}
                            />
                        </PaginationControlContainer>
                        {authenticatedUserUrn && (
                            <SearchResultsRecommendationsContainer>
                                <SearchResultsRecommendations
                                    userUrn={authenticatedUserUrn}
                                    query={query}
                                    filters={selectedFilters}
                                />
                            </SearchResultsRecommendationsContainer>
                        )}
                    </>
                )}
            </div>
        </>
    );
};
