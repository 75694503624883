import React from 'react';
import styled from 'styled-components';
import { useUserContext } from '../context/useUserContext';
import { HomePageRecommendations } from './HomePageRecommendations';

const BodyContainer = styled.div`
    width: 100%;
`;

export const HomePageBody = () => {
    const user = useUserContext()?.user;
    return <BodyContainer>{user && <HomePageRecommendations user={user} />}</BodyContainer>;
};
