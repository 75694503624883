import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, Tag, Typography } from 'antd';
import { useEntityRegistry } from '../../useEntityRegistry';
import { Maybe, Policy, PolicyState, PolicyType } from '../../../types.generated';
import { useAppConfig } from '../../useAppConfig';
import { convertLegacyResourceFilter, getFieldValues, mapResourceTypeToDisplayName } from './policyUtils';
import AvatarsGroup from '../AvatarsGroup';

type PrivilegeOptionType = {
    type?: string;
    name?: Maybe<string>;
};

type Props = {
    policy: Omit<Policy, 'urn'>;
    visible: boolean;
    onClose: () => void;
    privileges: PrivilegeOptionType[] | undefined;
};

/**
 * Component used for displaying the details about an existing Policy.
 */
export default function PolicyDetailsModal({ policy, visible, onClose, privileges }: Props) {
    const entityRegistry = useEntityRegistry();

    const isActive = policy?.state === PolicyState.Active;
    const isMetadataPolicy = policy?.type === PolicyType.Metadata;

    const resources = convertLegacyResourceFilter(policy?.resources);
    const resourceTypes = getFieldValues(resources?.filter, 'TYPE') || [];
    const resourceEntities = getFieldValues(resources?.filter, 'URN') || [];
    const domains = getFieldValues(resources?.filter, 'DOMAIN') || [];

    const {
        config: { policiesConfig },
    } = useAppConfig();

    // const activeActionButton = isActive ? (
    //     <Button danger disabled={!isEditable} onClick={() => onToggleActive(false)}>
    //         Deactivate
    //     </Button>
    // ) : (
    //     <Button type="primary" disabled={!isEditable} onClick={() => onToggleActive(true)}>
    //         Activate
    //     </Button>
    // );

    // const actionButtons = (
    //     <>
    //         <Button disabled={!isEditable} onClick={onEdit} type="primary">
    //             Edit
    //         </Button>
    //         {activeActionButton}
    //         <Button danger disabled={!isEditable} onClick={onRemove}>
    //             Delete
    //         </Button>
    //         <Button type="link" onClick={onClose}>
    //             Cancel
    //         </Button>
    //     </>
    // );

    const actionButtons = <Button onClick={onClose}>Close</Button>;

    const getDisplayName = (entity) => {
        if (!entity) {
            return null;
        }
        return entityRegistry.getDisplayName(entity.type, entity);
    };

    const getEntityTag = (criterionValue) => {
        return (
            (criterionValue.entity && (
                <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    to={() => `${entityRegistry.getEntityUrl(criterionValue.entity!.type, criterionValue.value)}`}
                >
                    {getDisplayName(criterionValue.entity)}
                </Link>
            )) || <Typography.Text>{criterionValue.value}</Typography.Text>
        );
    };

    const resourceOwnersField = (actors) => {
        if (!actors?.resourceOwners) {
            return <Tag>No</Tag>;
        }
        if ((actors?.resolvedOwnershipTypes?.length ?? 0) > 0) {
            return (
                <div>
                    {actors?.resolvedOwnershipTypes?.map((type) => (
                        <Tag>{type.info.name}</Tag>
                    ))}
                </div>
            );
        }
        return <Tag>Yes - All owners</Tag>;
    };

    return (
        <Modal title={policy?.name} visible={visible} onCancel={onClose} closable width={800} footer={actionButtons}>
            <div className="mb-3">
                <Typography.Title className="f-text-content mb-2">Type</Typography.Title>
                <Tag>{policy?.type}</Tag>
            </div>
            <div className="mb-3">
                <Typography.Title className="f-text-content mb-2">State</Typography.Title>
                <Tag className={isActive ? 'green-tag' : ''}>{policy?.state}</Tag>
            </div>
            <div className="mb-3">
                <Typography.Title className="f-text-content mb-2">Description</Typography.Title>
                <Typography.Text className="f-text-content" type="secondary">
                    {policy?.description}
                </Typography.Text>
            </div>
            {isMetadataPolicy && (
                <>
                    <div className="mb-3">
                        <Typography.Title className="f-text-content mb-2">Asset Type</Typography.Title>
                        {(resourceTypes?.length &&
                            resourceTypes.map((value, key) => {
                                return (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <Tag key={`type-${value.value}-${key}`}>
                                        {mapResourceTypeToDisplayName(
                                            value.value,
                                            policiesConfig?.resourcePrivileges || [],
                                        )}
                                    </Tag>
                                );
                            })) || <Tag>All</Tag>}
                    </div>
                    <div className="mb-3">
                        <Typography.Title className="f-text-content mb-2">Assets</Typography.Title>
                        {(resourceEntities?.length &&
                            resourceEntities.map((value, key) => {
                                return (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <Tag key={`resource-${value.value}-${key}`}>{getEntityTag(value)}</Tag>
                                );
                            })) || <Tag>All</Tag>}
                    </div>
                    <div className="mb-3">
                        <Typography.Title className="f-text-content mb-2">Domains</Typography.Title>
                        {(domains?.length &&
                            domains.map((value, key) => {
                                return (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <Tag key={`domain-${value.value}-${key}`}>{getEntityTag(value)}</Tag>
                                );
                            })) || <Tag>All</Tag>}
                    </div>
                </>
            )}
            <div className="mb-3">
                <Typography.Title className="f-text-content mb-2">Privileges</Typography.Title>
                {privileges?.map((priv, key) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Tag key={`${priv}-${key}`}>{priv?.name}</Tag>
                ))}
            </div>
            <div className="mb-3">
                <Typography.Title className="f-text-content mb-2">Applies to Owners</Typography.Title>
                {resourceOwnersField(policy?.actors)}
            </div>
            <div className="mb-3">
                <Typography.Title className="f-text-content mb-2">Applies to Users</Typography.Title>
                <AvatarsGroup
                    users={policy?.actors?.resolvedUsers}
                    entityRegistry={entityRegistry}
                    maxCount={50}
                    size={28}
                />
                {policy?.actors?.allUsers ? <Tag>All Users</Tag> : null}
            </div>
            <div className="mb-3">
                <Typography.Title className="f-text-content mb-2">Applies to Groups</Typography.Title>
                <AvatarsGroup
                    groups={policy?.actors?.resolvedGroups}
                    entityRegistry={entityRegistry}
                    maxCount={50}
                    size={28}
                />
                {policy?.actors?.allGroups ? <Tag>All Groups</Tag> : null}
            </div>
            <div className="mb-3">
                <Typography.Title className="f-text-content mb-2">Applies to Roles</Typography.Title>
                <AvatarsGroup
                    roles={policy?.actors?.resolvedRoles}
                    entityRegistry={entityRegistry}
                    maxCount={50}
                    size={28}
                />
            </div>
        </Modal>
    );
}
