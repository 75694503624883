import React, { useState } from 'react';
import styled from 'styled-components';

import { Button, Input, Modal, Typography, Form, Select, Spin, notification, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router';

import { AndFilterInput } from '../../../../../../types.generated';
import { getSearchCsvDownloadHeader, transformResultsToCsvRow } from './downloadAsCsvUtil';
import { downloadRowsAsCsv } from '../../../../../search/utils/csvUtils';
import { useEntityRegistry } from '../../../../../useEntityRegistry';
import { useEntityData } from '../../../EntityContext';
import analytics, { EventType } from '../../../../../analytics';
import { DownloadSearchResultsInput, DownloadSearchResults } from '../../../../../search/utils/types';

type Props = {
    downloadSearchResults: (input: DownloadSearchResultsInput) => Promise<DownloadSearchResults | null | undefined>;
    downloadType: any;
    filters: AndFilterInput[];
    query: string;
    viewUrn?: string;
    totalResults?: number;
    setIsDownloadingCsv: (isDownloadingCsv: boolean) => any;
    showDownloadAsCsvModal: boolean;
    setShowDownloadAsCsvModal: (showDownloadAsCsvModal: boolean) => any;
};

const StyledItem = styled(Form.Item)`
    margin-bottom: 0;
`;

const SEARCH_PAGE_SIZE_FOR_DOWNLOAD = 100;

export default function DownloadAsCsvModal({
    downloadSearchResults,
    filters,
    query,
    viewUrn,
    totalResults,
    downloadType,
    setIsDownloadingCsv,
    showDownloadAsCsvModal,
    setShowDownloadAsCsvModal,
}: Props) {
    const { entityData: entitySearchIsEmbeddedWithin } = useEntityData();
    const location = useLocation();
    const [form] = Form.useForm();

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const initialValues = {
        name: entitySearchIsEmbeddedWithin ? `${entitySearchIsEmbeddedWithin.name}_impact` : 'results',
        'file-format': 'csv',
    };

    const entityRegistry = useEntityRegistry();

    const openNotification = () => {
        notification.info({
            message: 'Preparing Download',
            description: totalResults
                ? `Creating CSV with ${totalResults} entities to download`
                : 'Creating CSV to download',
            placement: 'bottomRight',
            duration: null,
            icon: <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />,
        });
    };

    const closeNotification = () => {
        setTimeout(() => {
            notification.destroy();
        }, 3000);
    };

    const showFailedDownloadNotification = () => {
        notification.destroy();
        notification.error({
            message: 'Download Failed',
            description: 'The CSV file could not be downloaded',
            placement: 'bottomRight',
            duration: 3,
        });
    };

    const triggerCsvDownload = (filename) => {
        setIsDownloadingCsv(true);
        openNotification();

        let nextScrollId: string | null = null;
        let accumulatedResults: string[][] = [];

        analytics.event({
            type: EventType.DownloadAsCsvEvent,
            query,
            entityUrn: entitySearchIsEmbeddedWithin?.urn,
            path: location.pathname,
        });

        function fetchNextPage() {
            downloadSearchResults({
                scrollId: nextScrollId,
                query,
                count: SEARCH_PAGE_SIZE_FOR_DOWNLOAD,
                orFilters: filters,
                viewUrn,
            })
                .then((refetchData) => {
                    accumulatedResults = [
                        ...accumulatedResults,
                        ...transformResultsToCsvRow(refetchData?.searchResults || [], entityRegistry),
                    ];
                    // If we have a "next offset", then we continue.
                    // Otherwise, we terminate fetching.
                    if (refetchData?.nextScrollId) {
                        nextScrollId = refetchData?.nextScrollId;
                        fetchNextPage();
                    } else {
                        setIsDownloadingCsv(false);
                        closeNotification();
                        downloadRowsAsCsv(
                            getSearchCsvDownloadHeader(refetchData?.searchResults[0]),
                            accumulatedResults,
                            filename,
                        );
                    }
                })
                .catch((_) => {
                    setIsDownloadingCsv(false);
                    showFailedDownloadNotification();
                });
        }
        fetchNextPage();
    };

    const onFinish = (values: any) => {
        setShowDownloadAsCsvModal(false);

        const { checkDownloadType }: any = downloadType || {};

        if (checkDownloadType) {
            if (checkDownloadType?.type) {
                triggerCsvDownload(`${values.name}.${values['file-format']}`);
            } else {
                message.error('Something went wrong');
            }
        }
    };
    return (
        <Modal
            centered
            onCancel={() => setShowDownloadAsCsvModal(false)}
            title="Download as..."
            visible={showDownloadAsCsvModal}
            footer={
                <>
                    <Button onClick={() => setShowDownloadAsCsvModal(false)} type="text">
                        Close
                    </Button>
                    <Button
                        form="download-as"
                        key="submit"
                        htmlType="submit"
                        data-testid="csv-modal-download-button"
                        disabled={buttonDisabled}
                    >
                        Download
                    </Button>
                </>
            }
        >
            <Form
                onFinish={onFinish}
                form={form}
                layout="vertical"
                id="download-as"
                placeholder="datahub.csv"
                initialValues={initialValues}
                onFieldsChange={() => {
                    setButtonDisabled(form.getFieldsError().filter(({ errors }) => errors.length).length > 0);
                }}
            >
                <Form.Item label={<Typography.Text strong>Name</Typography.Text>}>
                    <StyledItem
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'required',
                            },
                        ]}
                    >
                        <Input placeholder="datahub" />
                    </StyledItem>
                </Form.Item>
                <Form.Item label={<Typography.Text strong>Format Type</Typography.Text>}>
                    <StyledItem name="file-format">
                        <Select>
                            <Select.Option value="csv">csv</Select.Option>
                        </Select>
                    </StyledItem>
                </Form.Item>
            </Form>
        </Modal>
    );
}
