import { Button, DatePicker, Divider, Dropdown, Input, Select } from 'antd';
import React, { RefObject, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import moment, { Moment } from 'moment';
import { CloseCircleFilled } from '@ant-design/icons';

import { FilterOperator, Maybe } from '../../../../../types.generated';

import { ReactComponent as KeyboardArrowDown } from '../../../../../images/keyboard_arrow_down.svg';

const ApplyButton = styled(Button)`
    justify-content: center;
`;

export enum FilterDropdownTypes {
    INPUT = 'input',
    TIME = 'time',
    DROP_DOWN = 'dropdown',
    CUSTOM = 'CUSTOM',
}

export interface IFilterDropdown {
    operators: Array<{ label: string; value: string | number }>;
    id: string;
    name: string;
    dropDownOptions?: Array<{ label: string; value: string | number }>;
    type?: FilterDropdownTypes;
    onResetFilter?: (key: string) => void;
    onApplyFilter?: (key: string, value: string | number, condition?: Maybe<FilterOperator>, negated?: boolean) => void;
    CustomRender?: React.FC<any>;
}
const FilterDropdown = (props: IFilterDropdown) => {
    const {
        id,
        name,
        operators,
        dropDownOptions = [],
        type,
        onApplyFilter = () => {},
        onResetFilter = () => {},
        CustomRender,
    } = props;

    const [fieldValue, setFieldValue] = useState<string | number | null | Moment | boolean>(null);
    const [isApplied, setIsApplied] = useState<boolean>(false);
    const [operator, setOperator] = useState<Maybe<FilterOperator>>(operators[0].value as FilterOperator);
    const dropdownRef: RefObject<HTMLDivElement> = useRef(null);
    const RenderComponent = CustomRender as React.FC<any>;

    const onChangeTextHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue(event.target.value);
    };

    const onSelectHandler = (text: string) => {
        setFieldValue(text);
    };

    const onChangeDateHandler = (date: Moment | null) => {
        setFieldValue(!date ? null : moment(date).valueOf().toString());
    };

    const applyFilter = () => {
        onApplyFilter(id, fieldValue as string, operator);
        setIsApplied(true);
        dropdownRef.current?.click();
    };

    const resetFilter = (event: React.MouseEvent<HTMLInputElement>) => {
        event.stopPropagation();
        onResetFilter(id);
        setIsApplied(false);
        setFieldValue(null);
    };

    const RenderInput = useMemo(() => {
        switch (type) {
            case FilterDropdownTypes.DROP_DOWN:
                return (
                    <Select
                        style={{ width: '100%' }}
                        placeholder="Select the value"
                        value={fieldValue as string | number}
                        onChange={setFieldValue}
                    >
                        {dropDownOptions.map(({ label, value }) => (
                            <Select.Option value={value}>{label}</Select.Option>
                        ))}
                    </Select>
                );
            case FilterDropdownTypes.TIME:
                return (
                    <DatePicker
                        style={{ width: '100%' }}
                        value={fieldValue ? moment.unix((fieldValue as number) / 1000) : null}
                        showTime
                        onChange={onChangeDateHandler}
                        format="MMM DD, YYYY h:mm a"
                    />
                );
            case FilterDropdownTypes.INPUT:
                return (
                    <Input
                        placeholder="Enter keyword or phrase"
                        value={fieldValue as string}
                        onChange={onChangeTextHandler}
                    />
                );
            default:
                return <RenderComponent value={fieldValue} onChange={onSelectHandler} />;
        }
    }, [dropDownOptions, fieldValue, type, RenderComponent]);

    return (
        <Dropdown
            trigger={['click']}
            className={`f-filter-dropdown ${isApplied && 'f-filter-applied'}`}
            dropdownRender={() => (
                <div className="f-filter-dropdown-menu">
                    <div className="pa-2">
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Select the operator"
                            value={operator}
                            onChange={setOperator}
                        >
                            {operators?.map(({ label, value }) => (
                                <Select.Option key={value} value={value}>
                                    {label}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                    <Divider style={{ margin: 0 }} />
                    <div className="pa-2" style={{ width: '100%' }}>
                        {RenderInput}
                    </div>
                    <div className="pa-2">
                        <ApplyButton block type="primary" onClick={applyFilter} disabled={!operator || !fieldValue}>
                            Apply
                        </ApplyButton>
                    </div>
                </div>
            )}
        >
            <div ref={dropdownRef}>
                <span>{name}</span>
                <KeyboardArrowDown className="pl-2" />
                {isApplied && <CloseCircleFilled className="pl-1" onClick={resetFilter} />}
            </div>
        </Dropdown>
    );
};

export default FilterDropdown;
