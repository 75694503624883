import React from 'react';
import styled from 'styled-components/macro';
import { message, Button, List, Modal } from 'antd';
import { LinkOutlined, DeleteOutlined } from '@ant-design/icons';
import { InstitutionalMemoryMetadata } from '../../../../../../types.generated';
import { useEntityData } from '../../../EntityContext';
import { formatDateString } from '../../../containers/profile/utils';
import { useRemoveLinkMutation } from '../../../../../../graphql/mutations.generated';

const LinkListItem = styled(List.Item)`
    border-radius: 4px;
    /* > .ant-btn {
        opacity: 0;
    } */
    &:hover {
        background-color: #f4f6f8;
        /* > .ant-btn {
            opacity: 1;
        } */
    }
`;

type LinkListProps = {
    refetch?: () => Promise<any>;
};

export const LinkList = ({ refetch }: LinkListProps) => {
    const { urn: entityUrn, entityData } = useEntityData();
    // const entityRegistry = useEntityRegistry();
    const [removeLinkMutation] = useRemoveLinkMutation();
    const links = entityData?.institutionalMemory?.elements || [];

    const handleDeleteLink = async (metadata: InstitutionalMemoryMetadata) => {
        try {
            await removeLinkMutation({
                variables: { input: { linkUrl: metadata.url, resourceUrn: metadata.associatedUrn || entityUrn } },
            });
            message.success({ content: 'Link Removed', duration: 2 });
        } catch (e: unknown) {
            message.destroy();
            if (e instanceof Error) {
                message.error({ content: `Error removing link: \n ${e.message || ''}`, duration: 2 });
            }
        }
        refetch?.();
    };

    const onRemoveLink = (metadata: InstitutionalMemoryMetadata, displayName: string) => {
        Modal.confirm({
            title: `Remove ${displayName}`,
            content: 'Are you sure you want to remove this link?',
            onOk() {
                handleDeleteLink(metadata);
            },
            onCancel() {},
            className: 'f-borderless-modal',
            okButtonProps: {
                type: undefined,
                danger: true,
            },
            okText: 'Yes',
            autoFocusButton: null,
            maskClosable: true,
            centered: true,
        });
    };

    // const isSystem = (name) => name === '__datahub_system';

    // Returns the redirect url incase if user is not system
    // const getDefaultPath = (author) => {
    //     return isSystem(author.username) ? '#' : entityRegistry.getEntityUrl(EntityType.CorpUser, author.urn);
    // };

    // const displayUserName = (name) => {
    //     console.log(name);
    //     if (isSystem(name)) {
    //         return 'System';
    //     }
    //     return name;
    // };

    return entityData ? (
        <>
            {links.length > 0 && (
                <List
                    dataSource={links}
                    renderItem={(link) => (
                        <LinkListItem
                            extra={
                                <Button
                                    onClick={() => onRemoveLink(link, link.description || link.label)}
                                    type="text"
                                    shape="circle"
                                    danger
                                >
                                    <DeleteOutlined />
                                </Button>
                            }
                        >
                            <List.Item.Meta
                                title={
                                    <Button type="link" href={link.url} target="_blank" rel="noreferrer">
                                        <LinkOutlined />
                                        <span>{link.description || link.label}</span>
                                    </Button>
                                }
                                description={
                                    <>
                                        <span className="f-text-small-content f-color-dark-black-s50">
                                            Added {formatDateString(link.created.time)}
                                        </span>
                                        {/* Hiding Autor name for now */}
                                        {/* <Link className="f-text-small-content" to={getDefaultPath(link.author)}>
                                            {displayUserName(link.author.username)}
                                        </Link> */}
                                    </>
                                }
                            />
                        </LinkListItem>
                    )}
                />
            )}
        </>
    ) : null;
};
