import React from 'react';
import styled from 'styled-components';
import { Button, Tag } from 'antd';
import { useHistory } from 'react-router-dom';
import { RecommendationContent, GlossaryTerm } from '../../../../types.generated';
import { navigateToSearchUrl } from '../../../search/utils/navigateToSearchUrl';
import { useEntityRegistry } from '../../../useEntityRegistry';
import { UnionType } from '../../../search/utils/constants';

const TermSearchListContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 16px 16px 8px;
`;

const TermContainer = styled.div`
    margin-bottom: 8px;
`;

type Props = {
    content: Array<RecommendationContent>;
    onClick?: (index: number) => void;
};

export const GlossaryTermSearchList = ({ content, onClick }: Props) => {
    const history = useHistory();
    const entityRegistry = useEntityRegistry();

    const terms: Array<GlossaryTerm> = content
        .map((cnt) => cnt.entity)
        .filter((entity) => entity !== null && entity !== undefined)
        .map((entity) => entity as GlossaryTerm);

    const onClickTerm = (term: any, index: number) => {
        onClick?.(index);
        navigateToSearchUrl({
            filters: [
                {
                    field: 'glossaryTerms',
                    values: [term.urn],
                },
                {
                    field: 'fieldGlossaryTerms',
                    values: [term.urn],
                },
            ],
            history,
            unionType: UnionType.OR,
        });
    };

    return (
        <TermSearchListContainer>
            {terms.map((term, index) => (
                <TermContainer>
                    <Button type="link" key={term.urn} onClick={() => onClickTerm(term, index)}>
                        <Tag className="label-tag" closable={false}>
                            {entityRegistry.getDisplayName(term.type, term)}
                        </Tag>
                    </Button>
                </TermContainer>
            ))}
        </TermSearchListContainer>
    );
};
