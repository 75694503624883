import { Button } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { FacetFilterInput, FacetMetadata } from '../../types.generated';
import { UnionType } from './utils/constants';
import { hasAdvancedFilters } from './utils/hasAdvancedFilters';
import { AdvancedSearchFilters } from './AdvancedSearchFilters';
import { SimpleSearchFilters } from './SimpleSearchFilters';
import { ViewBuilder } from '../entity/view/builder/ViewBuilder';
import { buildInitialViewState, fromUnionType } from '../entity/view/builder/utils';
import { ViewBuilderMode } from '../entity/view/builder/types';

type Props = {
    filters?: Array<FacetMetadata> | null;
    selectedFilters: Array<FacetFilterInput>;
    unionType: UnionType;
    loading: boolean;
    onChangeFilters: (filters: Array<FacetFilterInput>) => void;
    onChangeUnionType: (unionType: UnionType) => void;
};

const FiltersContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 260px;
    min-width: 260px;
    overflow-wrap: break-word;
    border-right: 1px solid;
    background-color: #ffffff;
    border-color: ${(props) => props.theme.styles['border-color-base']};
    height: 100%;
    min-height: 800px;
    max-height: 1510px;

    @media (max-width: 992px) {
        max-width: 100%;
        min-height: auto;
        max-height: none;
    }
`;

const FiltersHeader = styled.div`
    font-size: 14px;
    font-weight: 600;
    padding: 16px;
    width: 100%;
    height: 47px;
    line-height: 47px;
    border-bottom: 1px solid;
    border-color: ${(props) => props.theme.styles['border-color-base']};

    justify-content: space-between;
    align-items: center;
    display: flex;
`;

const SearchFiltersWrapper = styled.div`
    max-height: 100%;
    padding-top: 10px;
    overflow: auto;

    &::-webkit-scrollbar {
        height: 12px;
        width: 1px;
        background: #f2f2f2;
    }
    &::-webkit-scrollbar-thumb {
        background: #cccccc;
        -webkit-border-radius: 1ex;
        -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
    }
`;

const AdvancedSearchFiltersWrapper = styled.div`
    margin-top: 6px;
    margin-left: 12px;
    margin-right: 12px;
`;

// This component renders the entire filters section that allows toggling
// between the simplified search experience and advanced search
export const SearchFiltersSection = ({
    filters,
    selectedFilters,
    unionType,
    loading,
    onChangeFilters,
    onChangeUnionType,
}: Props) => {
    const onlyShowAdvancedFilters = hasAdvancedFilters(selectedFilters, unionType);
    const [showViewBuilder, setShowViewBuilder] = useState(false);
    const [seeAdvancedFilters, setSeeAdvancedFilters] = useState(onlyShowAdvancedFilters);

    return (
        <FiltersContainer>
            <FiltersHeader className="f-text-medium-content f-color-dark-black-s80">
                <span>Filter</span>
                <span>
                    <Button
                        disabled={onlyShowAdvancedFilters}
                        type="link"
                        onClick={() => setSeeAdvancedFilters(!seeAdvancedFilters)}
                    >
                        {seeAdvancedFilters ? 'Basic' : 'Advanced'}
                    </Button>
                </span>
            </FiltersHeader>
            <SearchFiltersWrapper>
                {seeAdvancedFilters ? (
                    <AdvancedSearchFiltersWrapper>
                        <AdvancedSearchFilters
                            unionType={unionType}
                            selectedFilters={selectedFilters}
                            onFilterSelect={(newFilters) => onChangeFilters(newFilters)}
                            onChangeUnionType={onChangeUnionType}
                            facets={filters || []}
                            loading={loading}
                        />
                        {showViewBuilder && (
                            <ViewBuilder
                                mode={ViewBuilderMode.EDITOR}
                                initialState={buildInitialViewState(selectedFilters, fromUnionType(unionType))}
                                onSubmit={() => setShowViewBuilder(false)}
                                onCancel={() => setShowViewBuilder(false)}
                            />
                        )}
                    </AdvancedSearchFiltersWrapper>
                ) : (
                    <SimpleSearchFilters
                        loading={loading}
                        facets={filters || []}
                        selectedFilters={selectedFilters}
                        onFilterSelect={(newFilters) => onChangeFilters(newFilters)}
                    />
                )}
            </SearchFiltersWrapper>
        </FiltersContainer>
    );
};
