import React from 'react';

import { Col, Card, Typography, Divider } from 'antd';

import { Highlight as HighlightType } from '../../../types.generated';
import { formatNumber } from '../../shared/formatNumber';

type Props = {
    highlight: HighlightType;
    shortenValue?: boolean;
};

export const Highlight = ({ highlight, shortenValue }: Props) => {
    return (
        <Col flex="auto">
            <Card className="mb-2">
                <div className="pa-2">
                    <div className="f-left-border-highlight darker-border py-0">
                        <Typography.Title className="f-text-big-title mb-2" level={1}>
                            {(shortenValue && formatNumber(highlight.value)) || highlight.value}
                        </Typography.Title>
                        <Typography.Text className="f-text-medium-content f-color-dark-black-s50">
                            {highlight.title}
                        </Typography.Text>
                    </div>
                </div>
                <Divider className="ma-0" />
                <div className="pa-2">
                    <Typography.Text className="f-text-small-content f-color-dark-black-s50">
                        {highlight.body || '-'}
                    </Typography.Text>
                </div>
            </Card>
        </Col>
    );
};
