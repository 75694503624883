import React, { useEffect } from 'react';
import { HomePageHeader } from './HomePageHeader';
import { HomePageBody } from './HomePageBody';
import analytics, { EventType } from '../analytics';

export const HomePage = () => {
    useEffect(() => {
        analytics.event({ type: EventType.HomePageViewEvent });
    }, []);
    return (
        <>
            <HomePageHeader />
            <HomePageBody />
        </>
    );
};
