import React from 'react';
import styled from 'styled-components';
import { Divider } from 'antd';

import TagTermGroup from '../../../../../shared/tags/TagTermGroup';
import { SidebarHeader } from './SidebarHeader';
import { useEntityData, useMutationUrn, useRefetch } from '../../../EntityContext';

const StyledDivider = styled(Divider)`
    margin: 16px 0;
`;
interface Props {
    properties?: any;
    readOnly?: boolean;
}

export const SidebarTagsSection = ({ properties, readOnly }: Props) => {
    const canAddTag = properties?.hasTags;
    const canAddTerm = properties?.hasTerms;

    const mutationUrn = useMutationUrn();

    const { entityType, entityData } = useEntityData();

    const refetch = useRefetch();

    return (
        <>
            <div className="pa-4">
                <span>
                    <SidebarHeader title="Tags" />
                    <TagTermGroup
                        editableTags={entityData?.globalTags}
                        canAddTag={canAddTag}
                        canRemove
                        showEmptyMessage
                        entityUrn={mutationUrn}
                        entityType={entityType}
                        refetch={refetch}
                        readOnly={readOnly}
                        fontSize={12}
                    />
                </span>
            </div>
            <StyledDivider />
            <div className="pa-4">
                <span>
                    <SidebarHeader title="Glossary Terms" />
                    <TagTermGroup
                        editableGlossaryTerms={entityData?.glossaryTerms}
                        canAddTerm={canAddTerm}
                        canRemove
                        showEmptyMessage
                        entityUrn={mutationUrn}
                        entityType={entityType}
                        refetch={refetch}
                        readOnly={readOnly}
                        fontSize={12}
                    />
                </span>
            </div>
        </>
    );
};
