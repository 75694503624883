import React, { useState, useRef } from 'react';
import { Select, Tag } from 'antd';

import { useGetSearchResultsLazyQuery } from '../../../../../../../graphql/search.generated';
import { CorpUser, EntityType, OwnerEntityType } from '../../../../../../../types.generated';
import { useEntityRegistry } from '../../../../../../useEntityRegistry';

import { useGetRecommendations } from '../../../../../../shared/recommendation';
import CustomAvatar from '../../../../../../shared/avatar/CustomAvatar';
import { OwnerLabel } from '../../../../../../shared/OwnerLabel';

export const OwnersAutocomplete = ({ selectedOwners, setSelectedOwners }: any) => {
    const entityRegistry = useEntityRegistry();

    // Renders a search result in the select dropdown.
    const renderSearchResult = (entity: any) => {
        const avatarUrl =
            (entity.type === EntityType.CorpUser && (entity as CorpUser).editableProperties?.pictureLink) || undefined;
        const displayName = entityRegistry.getDisplayName(entity.type, entity);
        return (
            <Select.Option value={entity.urn} key={entity.urn}>
                <OwnerLabel name={displayName} avatarUrl={avatarUrl} type={entity.type} />
            </Select.Option>
        );
    };

    const [inputValue, setInputValue] = useState('');
    const [selectedOwnersDetails, setSelectedOwnersDetails]: any = useState([]);

    const [userSearch, { data: userSearchData }] = useGetSearchResultsLazyQuery();
    const inputEl = useRef(null);

    const [recommendedData] = useGetRecommendations([EntityType.CorpUser]);
    const userSearchResults = userSearchData?.search?.searchResults?.map((searchResult) => searchResult.entity) || [];

    const ownerResult = !inputValue || inputValue.length === 0 ? recommendedData : userSearchResults;

    const ownerSearchOptions = ownerResult?.map((result) => {
        return renderSearchResult(result);
    });

    // Invokes the search API as the owner types
    const handleSearch = (type: EntityType, text: string, searchQuery: any) => {
        searchQuery({
            variables: {
                input: {
                    type,
                    query: text,
                    start: 0,
                    count: 5,
                },
            },
        });
    };

    // Invokes the user search API for both users and groups.
    const handleActorSearch = (text: string) => {
        handleSearch(EntityType.CorpUser, text, userSearch);
    };

    function handleBlur() {
        setInputValue('');
    }

    /**
     * When a owner search result is selected, add the new owner  to the selectedOwners
     * value: {ownerUrn: string, ownerEntityType: EntityType}
     */
    const onSelectOwner = (selectedValue: { key: string; label: React.ReactNode; value: string }) => {
        if (inputEl && inputEl.current) {
            (inputEl.current as any).blur();
        }

        const ownerDetails: any = ownerResult?.find((details: any) => details.urn === selectedValue.value);

        const isSelected = selectedOwners.some((owner) => owner.value.ownerUrn === selectedValue.value);

        if (isSelected) {
            return;
        }
        const filteredActors = ownerResult
            ?.filter((entity) => entity.urn === selectedValue.value)
            .map((entity) => entity);

        if (filteredActors?.length) {
            const actor = filteredActors[0];
            const ownerEntityType =
                actor && actor.type === EntityType.CorpGroup ? OwnerEntityType.CorpGroup : OwnerEntityType.CorpUser;
            const newValues = [
                ...selectedOwners,
                {
                    label: selectedValue.value,
                    value: {
                        ownerUrn: selectedValue.value,
                        ownerEntityType: ownerEntityType as unknown as EntityType,
                    },
                },
            ];

            setSelectedOwnersDetails([...selectedOwnersDetails, ownerDetails]);
            setSelectedOwners(newValues);
        }
    };

    // When a owner search result is deselected, remove the Owner
    const onDeselectOwner = (selectedValue: { key: string; label: React.ReactNode; value: any }) => {
        setInputValue('');
        const newValues = selectedOwners.filter(
            (owner) => owner.label !== selectedValue.value && owner.value.ownerUrn !== selectedValue.value?.ownerUrn,
        );
        const removeDetails = selectedOwnersDetails.filter((owner) => owner.urn !== selectedValue.value?.ownerUrn);

        setSelectedOwners(newValues);
        setSelectedOwnersDetails(removeDetails);
    };

    /**
     * Render a tag for the selected owner.
     *
     * @param {Object} owner - The owner object.
     * @param {string} owner.value.ownerUrn - The URN of the owner.
     * @returns {JSX.Element} - The rendered tag component.
     */
    function renderTag(owner) {
        const ownerDetails: any = selectedOwnersDetails.find((details: any) => details.urn === owner.value.ownerUrn);
        const displayName = entityRegistry.getDisplayName(ownerDetails.type, ownerDetails) || owner.label;

        const avatarUrl =
            (ownerDetails.type === EntityType.CorpUser && (ownerDetails as CorpUser).editableProperties?.pictureLink) ||
            undefined;

        return (
            <Tag className="base-tag" key={owner?.value.ownerUrn} closable onClose={() => onDeselectOwner(owner)}>
                <CustomAvatar size={16} photoUrl={avatarUrl} name={displayName} />
                {displayName}
            </Tag>
        );
    }

    return (
        <div>
            <Select
                autoFocus
                showSearch
                labelInValue
                onSelect={(owner: any) => onSelectOwner(owner)}
                onDeselect={(owner: any) => onDeselectOwner(owner)}
                onSearch={(value: string) => {
                    handleActorSearch(value.trim());
                    setInputValue(value.trim());
                }}
                onBlur={handleBlur}
                style={{
                    width: '100%',
                }}
                value={inputValue || null}
                placeholder="Search for users"
            >
                {ownerSearchOptions}
            </Select>
            <div className="mt-2">{selectedOwners.map((owner) => renderTag(owner))}</div>
        </div>
    );
};
