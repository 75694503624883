import React from 'react';
import { Divider, Popover, Tooltip, Typography } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import { getLocaleTimezone } from '../../../../shared/time/timeUtils';
import { useGetUserQuery } from '../../../../../graphql/user.generated';

import { ANTD_GRAY } from '../../constants';

const UNDER_REVIEW = 'Under_Review';
const CERTIFIED = 'Certified';

interface GovernanceStatusProps {
    themeColor?: string;
}

const GovernanceStatusContainer = styled.div<GovernanceStatusProps>`
    height: 18px;
    border: 1px solid ${(props): any => props.themeColor || '#8F9094'};
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props): any => props.themeColor || '#8F9094'};
    margin-left: 0px;
    margin-right: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 4px;
    padding-left: 4px;
`;

const StatusText = styled.div<GovernanceStatusProps>`
    padding-right: 2px;
    padding-left: 2px;
    font-size: 10px;
    color: ${(props): any => props.themeColor || '#8F9094'};
`;

const StatusTitle = styled(Typography.Text)`
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: bold;
`;

const StatusSubTitle = styled(Typography.Text)`
    display: block;
    margin-bottom: 5px;
`;

const LastEvaluatedAtLabel = styled.div`
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    color: ${ANTD_GRAY[7]};
`;

const ThinDivider = styled(Divider)`
    margin-top: 8px;
    margin-bottom: 8px;
`;

type Props = {
    governance: any;
};

export const GovernanceStatusPill = ({ governance }: Props) => {
    // Getting User for the Tooltip
    const { data } = useGetUserQuery({ variables: { urn: governance?.created?.actor, groupsCount: 0 } });
    const user = data?.corpUser?.info?.displayName || '';

    /**
     * Retrieves the theme color based on the governance status.
     * @returns {string} The color code based on the governance status.
     */
    const getThemeColor = () => {
        switch (governance.status) {
            case UNDER_REVIEW:
                return '#1ca8dd';
            case CERTIFIED:
                return '#86B62C';
            default:
                return '#8F9094';
        }
    };

    const color = getThemeColor();

    const getStatusClass = () => {
        switch (governance.status) {
            case UNDER_REVIEW:
                return 'f-under-review';
            case CERTIFIED:
                return 'f-certified';
            default:
                return 'f-default-status';
        }
    };

    const statusClass = getStatusClass();

    const localeTimezone = getLocaleTimezone();
    const decommissionTimeLocal =
        (governance.created.time &&
            `Time: ${moment(governance.created.time).format('DD/MMM/YYYY')} (${localeTimezone})`) ||
        undefined;
    const decommissionTimeGMT =
        governance.created.time && moment(governance.created.time).utc().format('dddd, DD/MMM/YYYY HH:mm:ss z');

    const hasDetails = governance.doc !== '' || governance.created.time !== null;

    /**
     * Checks if the governance status is 'None'.
     * @type {boolean}
     */
    const isNone = governance?.status === 'None';

    return !isNone ? (
        <Popover
            className="ml-1"
            overlayStyle={{ maxWidth: 240 }}
            placement="right"
            content={
                hasDetails ? (
                    <>
                        <StatusTitle>Governance Doc</StatusTitle>
                        <ThinDivider />
                        <StatusSubTitle>{governance.doc}</StatusSubTitle>
                        <Typography.Text type="secondary">
                            <Tooltip placement="right" title={decommissionTimeGMT}>
                                <LastEvaluatedAtLabel className="f-color-high-contrast">
                                    {decommissionTimeLocal}
                                </LastEvaluatedAtLabel>
                            </Tooltip>
                        </Typography.Text>
                        <StatusSubTitle type="secondary">Last updated by: {user}</StatusSubTitle>
                    </>
                ) : (
                    'No additional details'
                )
            }
        >
            <GovernanceStatusContainer themeColor={color} className={statusClass}>
                <StatusText themeColor={color}>{governance.displayName.toUpperCase()}</StatusText>
            </GovernanceStatusContainer>
        </Popover>
    ) : (
        <></>
    );
};
