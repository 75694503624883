import React, { useState } from 'react';
import { Card, Checkbox, Row, Space, Divider, Menu, Dropdown } from 'antd';
import styled from 'styled-components';

import { ReactComponent as DownloadOutlined } from '../../../images/downloaded-outline.svg';
import { ReactComponent as MoreAction } from '../../../images/more-vert.svg';
import { GlossaryNode, GlossaryTerm } from '../../../types.generated';
import { Notification } from '../../shared/Notification';
import { Message } from '../../shared/Message';

import { ChildGlossaryTermFragment } from '../../../graphql/glossaryNode.generated';
import { useExportEntityMutation } from '../../../graphql/mutations.generated';
import { GlossaryNodeFragment } from '../../../graphql/fragments.generated';

const GREY_COLOR = '#5F767C';

const styles = {
    row: { padding: '12px 16px 12px 24px' },
};

const BrowseDropDown = styled(Dropdown)`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

const BrowseMenu = styled(Menu)`
    padding: 0px;
    border-radius: 4px;
    min-width: 140px !important;
`;

interface Props {
    nodes: (GlossaryNode | GlossaryNodeFragment)[];
    terms: (GlossaryTerm | ChildGlossaryTermFragment)[];
    selectedItems: string[];
    setSelectedItems: (event) => void;
}

function ChildrenTab({ nodes, terms, selectedItems, setSelectedItems }: Props) {
    const [isCsvExporting, setIsCsvExporting] = useState<boolean>(false);
    const [exportEntityMutation] = useExportEntityMutation();
    const [exportEntity, setExportEntity]: any = useState(null);

    // Check if all items are selected.
    const isAllSelected = () => {
        if (!nodes || !terms) {
            return false;
        }
        return selectedItems.length === nodes.length + terms.length;
    };

    // Handle select all items.
    const handleSelectAll = (e) => {
        const allItems = [...(nodes || []), ...(terms || [])].map((item) => item.urn);
        setSelectedItems(e.target.checked ? allItems : []);
    };

    /**
     * Handles the export of the selected datasets to a CSV file.
     * Calls the exportEntityMutation to export the selected datasets to a CSV file.
     * Displays a notification message to the user once the export is completed.
     * Clears the selectedItems state and sets the isAllSelected state to false.
     */
    const exportCsv = () => {
        const glossaryNodeUrns = nodes.filter((item) => selectedItems.includes(item?.urn)).map((item) => item?.urn);
        const glossaryTermUrns = terms.filter((item) => selectedItems.includes(item?.urn)).map((item) => item?.urn);

        setIsCsvExporting(true);
        setExportEntity(null);

        exportEntityMutation({
            variables: {
                input: {
                    glossaryTerm: {
                        glossaryNodeUrns,
                        glossaryTermUrns,
                    },
                    entityType: 'glossaryTerm',
                },
            },
        })
            .then((res = {}) => {
                const { data } = res;
                setExportEntity(data?.exportEntity);
                setSelectedItems([]);
                // setIsAllSelected(false);
            })
            .finally(() => setIsCsvExporting(false));
    };

    return (
        <>
            {exportEntity && <Notification exportEntity={exportEntity} />}

            {isCsvExporting && <Message type="loading" content="Loading..." style={{ marginTop: '10%' }} />}

            <Card>
                <Row style={styles.row} justify="space-between" align="middle">
                    <Space size="middle" align="center">
                        <Checkbox checked={isAllSelected()} onChange={handleSelectAll}>
                            Select All
                        </Checkbox>
                    </Space>
                    <Row align="middle">
                        <BrowseDropDown
                            overlay={
                                <BrowseMenu>
                                    <Menu.Item
                                        key="1"
                                        onClick={exportCsv}
                                        disabled={selectedItems.length === 0}
                                        className="f-color-gray-s100"
                                        icon={<DownloadOutlined style={{ width: 16 }} color="#5F767C" />}
                                    >
                                        Export
                                    </Menu.Item>
                                </BrowseMenu>
                            }
                            trigger={['click']}
                        >
                            <Row align="middle" style={{ gap: 8 }}>
                                <MoreAction
                                    data-testid="entity-header-dropdown"
                                    width={12}
                                    height={12}
                                    color={GREY_COLOR}
                                />
                                <span className="f-color-gray-s100">Actions</span>
                            </Row>
                        </BrowseDropDown>
                    </Row>
                </Row>
                <Divider className="ma-0" />
            </Card>
        </>
    );
}

export default ChildrenTab;
