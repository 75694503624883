import React from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';
import { RoutedTabs } from '../shared/RoutedTabs';
// import { GroupList } from './group/GroupList';
import { UserList } from './user/UserList';

const PageContainer = styled.div`
    padding-top: 16px;
    width: 100%;
`;

const PageHeaderContainer = styled.div`
    && {
        padding-left: 24px;
    }
`;

const Content = styled.div`
    &&& .ant-tabs-nav {
        margin-bottom: 8px;
    }
    color: #262626;

    &&& .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    }
`;

enum TabType {
    Users = 'Users',
    Groups = 'Groups',
}
const ENABLED_TAB_TYPES = [TabType.Users, TabType.Groups];

export const ManageIdentities = () => {
    /**
     * Determines which view should be visible: users or groups list.
     */

    const getTabs = () => {
        return [
            {
                name: TabType.Users,
                path: TabType.Users.toLocaleLowerCase(),
                content: <UserList />,
                display: {
                    enabled: () => true,
                },
            },
            /* TODO: commented the Group tab for now. */
            // {
            //     name: TabType.Groups,
            //     path: TabType.Groups.toLocaleLowerCase(),
            //     content: <GroupList />,
            //     display: {
            //         enabled: () => true,
            //     },
            // },
        ].filter((tab) => ENABLED_TAB_TYPES.includes(tab.name));
    };

    const defaultTabPath = getTabs() && getTabs()?.length > 0 ? getTabs()[0].path : '';
    const onTabChange = () => null;

    return (
        <PageContainer>
            <PageHeaderContainer>
                <Typography.Text className="page-heading">Manage Users</Typography.Text>
                <Typography.Paragraph type="secondary" className="mt-2">
                    View your Data Catalog users. Take administrative actions.
                </Typography.Paragraph>
            </PageHeaderContainer>
            <Content>
                <RoutedTabs defaultPath={defaultTabPath} tabs={getTabs()} onTabChange={onTabChange} />
            </Content>
        </PageContainer>
    );
};
