import { Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';

const Section = styled.div`
    width: auto;
    margin-top: 40px;
    margin-left: 60px;
`;

const MessageSection = styled.div`
    margin-bottom: 20px;
`;

const DetailParagraph = styled(Typography.Paragraph)`
    font-size: 14px;
`;

export const ErrorSection = (): JSX.Element => {
    return (
        <Section>
            <div>
                <MessageSection>
                    <Typography.Title level={2}>Something went wrong.</Typography.Title>
                    <DetailParagraph type="secondary">
                        An unexpected error occurred. Please try again later, or reach out to your administrator
                    </DetailParagraph>
                </MessageSection>
            </div>
        </Section>
    );
};
