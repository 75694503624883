import React, { useEffect } from 'react';
import { Button, notification } from 'antd';

export const Notification = ({ exportEntity }: any) => {
    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        const key = `open${Date.now()}`;

        const title = exportEntity?.exportDownloadLink ? 'Export Completed' : 'Export Started';
        const content = exportEntity?.exportDownloadLink
            ? 'Your file is ready, please use this link to download it'
            : `Once file is ready, we'll email you the link.`;

        api.open({
            message: <span />,
            description: (
                <div className="notification-description">
                    <div style={{ maxWidth: 300 }}>
                        <div style={{ fontWeight: 'bold' }}>{title}</div>
                        <span>{content}</span>
                        {exportEntity?.exportDownloadLink && (
                            <Button
                                type="link"
                                target="_blank"
                                href={exportEntity.exportDownloadLink}
                                style={{ textDecoration: 'underline', color: '#70B246' }}
                            >
                                Click here
                            </Button>
                        )}
                    </div>
                    <Button type="link" className="f-color-gray-s100" onClick={() => notification.close(key)}>
                        CLOSE
                    </Button>
                </div>
            ),
            closeIcon: <></>,
            key,
            duration: 10,
            className: 'notification-success',
        });
    }, [exportEntity, api]); // Dependency array ensures effect runs only when exportEntity changes

    return <>{contextHolder}</>; // This component does not render anything
};
