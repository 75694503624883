import React from 'react';
import { Typography } from 'antd';
import { OnboardingStep } from '../OnboardingStep';

export const USERS_INTRO_ID = 'users-intro';
export const USERS_SSO_ID = 'users-sso';
export const USERS_INVITE_LINK_ID = 'users-invite-link';
export const USERS_ASSIGN_ROLE_ID = 'users-assign-role';

export const UsersOnboardingConfig: OnboardingStep[] = [
    {
        id: USERS_INTRO_ID,
        title: 'Users',
        content: (
            <Typography.Paragraph>
                <p>
                    Welcome to Data Catalog <strong>Users</strong>!
                </p>
                <p>
                    There are a few different ways to onboard new <strong>Users</strong> onto Data Catalog.
                </p>
            </Typography.Paragraph>
        ),
    },
    {
        id: USERS_SSO_ID,
        title: 'Configuring Single Sign-On (SSO)',
        content: (
            <Typography.Paragraph>
                <p>
                    The preferred way to onboard new <strong>Users</strong> is to use <strong>Single Sign-On</strong>.
                    Currently, Data Catalog supports OIDC SSO.
                </p>
                <p>
                    {/* Removed the anchor tag for now */}
                    Learn more about how to configure <strong>Single Sign-On</strong> <span>here.</span>
                </p>
            </Typography.Paragraph>
        ),
    },
    {
        id: USERS_INVITE_LINK_ID,
        selector: `#${USERS_INVITE_LINK_ID}`,
        title: 'Invite New Users',
        content: (
            <Typography.Paragraph>
                <p>
                    Easily share an invite link with your colleagues to onboard them onto Data Catalog. Optionally
                    assign a <strong>Role</strong> to anyone who joins using the link.
                </p>
                <p>
                    {/* Removed the anchor tag for now */}
                    Learn more about configuring invite links <span>here.</span>
                </p>
            </Typography.Paragraph>
        ),
    },
    {
        id: USERS_ASSIGN_ROLE_ID,
        selector: `#${USERS_ASSIGN_ROLE_ID}`,
        title: 'Assigning Roles',
        content: (
            <Typography.Paragraph>
                <p>
                    You can assign <strong>Roles</strong> to existing <strong>Users</strong> here.
                </p>
                <p>
                    {/* Removed the anchor tag for now */}
                    Learn more about <strong>Roles</strong> <span> here.</span>
                </p>
            </Typography.Paragraph>
        ),
    },
];
