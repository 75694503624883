/* eslint-disable */
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ApplianceListQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ApplianceListQuery = { __typename?: 'Query' } & {
    applianceList?: Types.Maybe<
        Array<
            Types.Maybe<
                { __typename?: 'Appliance' } & Pick<
                    Types.Appliance,
                    'applianceId' | 'name' | 'queueName' | 'status' | 'unstable'
                >
            >
        >
    >;
};

export const ApplianceListDocument = gql`
    query applianceList {
        applianceList {
            applianceId
            name
            queueName
            status
            unstable
        }
    }
`;

/**
 * __useApplianceListQuery__
 *
 * To run a query within a React component, call `useApplianceListQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplianceListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplianceListQuery({
 *   variables: {
 *   },
 * });
 */
export function useApplianceListQuery(
    baseOptions?: Apollo.QueryHookOptions<ApplianceListQuery, ApplianceListQueryVariables>,
) {
    return Apollo.useQuery<ApplianceListQuery, ApplianceListQueryVariables>(ApplianceListDocument, baseOptions);
}
export function useApplianceListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ApplianceListQuery, ApplianceListQueryVariables>,
) {
    return Apollo.useLazyQuery<ApplianceListQuery, ApplianceListQueryVariables>(ApplianceListDocument, baseOptions);
}
export type ApplianceListQueryHookResult = ReturnType<typeof useApplianceListQuery>;
export type ApplianceListLazyQueryHookResult = ReturnType<typeof useApplianceListLazyQuery>;
export type ApplianceListQueryResult = Apollo.QueryResult<ApplianceListQuery, ApplianceListQueryVariables>;
