import { useEffect, useState } from 'react';
import { FacetFilterInput } from '../../../types.generated';
import utilService from '../../../utils/util.service';

interface IPagination {
    offset: number;
    limit: number;
    totalRecords: number;
}

interface IGridRequest {
    urn: string;
    filters: Array<FacetFilterInput> | never[];
    orderBy: string;
    from?: number;
    pagination: IPagination;
}

const { debounce } = utilService;
const useTableRequest = (props: IGridRequest) => {
    let filtersMap: Record<string, FacetFilterInput> = {};

    const [tablePagination, setTablePagination] = useState<IPagination>({
        ...props.pagination,
    });

    const fromItem = tablePagination.totalRecords ? tablePagination.offset + 1 : 0;
    const toItem =
        tablePagination.offset + tablePagination.limit < tablePagination.totalRecords
            ? tablePagination.offset + tablePagination.limit
            : tablePagination.totalRecords;

    const [tableRequest, setTableRequest] = useState({
        urn: props.urn,
        filters: props.filters,
        orderBy: props.orderBy,
        pageSize: tablePagination.limit ?? 10,
        from: tablePagination.offset ?? 0,
    });

    useEffect(() => {
        setTableRequest((prev) => ({
            ...prev,
            from: tablePagination.offset,
        }));
    }, [tablePagination.offset]);

    const createFilter = () => {
        tableRequest.filters = Object.values(filtersMap) || [];
        setTablePagination((prev) => ({
            ...prev,
            offset: 0,
        }));
        setTableRequest({ ...tableRequest, from: 0 });
    };

    const onSearch = debounce((value: string) => {
        filtersMap.search = {
            field: 'search',
            value,
        };
        createFilter();
    }, 500);

    const onApplyFilter = (field: string, value, condition, negated = false) => {
        filtersMap[field] = {
            field,
            value,
            condition,
            negated,
        };
        createFilter();
    };

    const onResetFilter = (field: string) => {
        delete filtersMap[field];
        createFilter();
    };

    const onClearFilter = () => {
        filtersMap = {};
        createFilter();
    };

    const fetchNextPage = () => {
        const offset = tablePagination.offset + tablePagination.limit;
        setTablePagination((prev) => ({
            ...prev,
            offset,
        }));
    };

    const fetchPrevPage = () => {
        const offset = tablePagination.offset - tablePagination.limit;
        setTablePagination((prev) => ({
            ...prev,
            offset,
        }));
    };

    const renderTextPagination = () => {
        return `${fromItem} - ${toItem} of ${tablePagination.totalRecords}`;
    };

    return {
        tablePagination,
        tableRequest,
        hasPrevPage: tablePagination.offset === 0,
        hasNextPage: tablePagination.offset + tablePagination.limit >= tablePagination.totalRecords,
        fromItem,
        toItem,
        totalRecords: tablePagination.totalRecords,
        onSearch,
        onApplyFilter,
        onResetFilter,
        onClearFilter,
        fetchNextPage,
        fetchPrevPage,
        setTablePagination,
        renderTextPagination,
    };
};

export default useTableRequest;
