import OwnerLookup from '../../../../../lookups/OwnerLookup';
import { FilterDropdownTypes, IFilterDropdown } from '../../../components/FilterDropdown/FilterDropdown';
import { ActivityStatus } from '../TableColumns/ActivityTagColumn';

export enum OperatorType {
    EQUAL = 'EQUAL',
    CONTAIN = 'CONTAIN',
    IN = 'IN',
    GREATER_THAN = 'GREATER_THAN',
    GREATER_THAN_OR_EQUAL_TO = 'GREATER_THAN_OR_EQUAL_TO',
    LESS_THAN = 'LESS_THAN',
    LESS_THAN_OR_EQUAL_TO = 'LESS_THAN_OR_EQUAL_TO',
}

export const FilterOptions: IFilterDropdown[] = [
    {
        id: 'time',
        name: 'Time',
        type: FilterDropdownTypes.TIME,
        operators: [
            {
                label: 'Greater than',
                value: OperatorType.GREATER_THAN,
            },
            {
                label: 'Greater than or equal to',
                value: OperatorType.GREATER_THAN_OR_EQUAL_TO,
            },
            {
                label: 'Less than',
                value: OperatorType.LESS_THAN,
            },
            {
                label: 'Less than or equal to',
                value: OperatorType.LESS_THAN_OR_EQUAL_TO,
            },
        ],
    },
    {
        id: 'activity',
        name: 'Activity',
        type: FilterDropdownTypes.DROP_DOWN,
        dropDownOptions: (Object.keys(ActivityStatus) as (keyof typeof ActivityStatus)[]).reduce((acc, cur) => {
            acc.push({ label: cur, value: cur });
            return acc;
        }, [] as { label: string; value: string | number }[]),
        operators: [
            {
                label: 'Equal',
                value: OperatorType.EQUAL,
            },
        ],
    },
    {
        id: 'aspect',
        name: 'Attribute',
        type: FilterDropdownTypes.INPUT,
        operators: [
            {
                label: 'Equal',
                value: OperatorType.EQUAL,
            },
            {
                label: 'Contain',
                value: OperatorType.CONTAIN,
            },
        ],
    },
    {
        id: 'oldValue',
        name: 'Old Value',
        type: FilterDropdownTypes.INPUT,
        operators: [
            {
                label: 'Equal',
                value: OperatorType.EQUAL,
            },
            {
                label: 'Contain',
                value: OperatorType.CONTAIN,
            },
        ],
    },
    {
        id: 'newValue',
        name: 'New Value',
        type: FilterDropdownTypes.INPUT,
        operators: [
            {
                label: 'Equal',
                value: OperatorType.EQUAL,
            },
            {
                label: 'Contain',
                value: OperatorType.CONTAIN,
            },
        ],
    },
    {
        id: 'actorUrn',
        name: 'User',
        type: FilterDropdownTypes.CUSTOM,
        CustomRender: OwnerLookup,
        operators: [
            {
                label: 'Equal',
                value: OperatorType.EQUAL,
            },
        ],
    },
];
