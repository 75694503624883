import React from 'react';
import { Button, Form, message, Modal, Select } from 'antd';
import { useAddAspectGovernessAspectMutation } from '../../../../graphql/mutations.generated';

type Props = {
    entityData: any;
    onClose: () => void;
    refetch?: () => void;
};

export const UpdateGovernanceStatusModal = ({ entityData, onClose, refetch }: Props) => {
    const [updateAspectGovernance] = useAddAspectGovernessAspectMutation();
    const [form] = Form.useForm();

    const handleClose = () => {
        form.resetFields();
        onClose();
    };
    const handleOk = async (formData: any) => {
        message.loading({ content: 'Updating...' });
        try {
            await updateAspectGovernance({
                variables: {
                    urn: entityData.urn,
                    input: {
                        status: formData.status,
                    },
                },
            });
            message.destroy();
            message.success({ content: 'Status Updated', duration: 2 });
        } catch (e: unknown) {
            message.destroy();
        }
        refetch?.();
        handleClose();
    };

    return (
        <Modal
            title="Update Governance Status"
            visible
            onCancel={handleClose}
            keyboard
            centered
            footer={
                <>
                    <Button form="updateGovernanceStatusForm" key="submit" htmlType="submit" type="primary">
                        Update
                    </Button>
                    <Button onClick={handleClose}>Cancel</Button>
                </>
            }
        >
            <Form
                form={form}
                name="updateGovernanceStatusForm"
                onFinish={handleOk}
                layout="vertical"
                initialValues={{ status: entityData?.GovernanceStatusInfo?.status || 'None' }}
            >
                <Form.Item name="status" label="Select Status">
                    <Select
                        options={[
                            { value: 'Under_Review', label: 'Under Review' },
                            { value: 'Certified', label: 'Certified' },
                            { value: 'Draft', label: 'Draft' },
                            { value: 'None', label: 'None' },
                        ]}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};
