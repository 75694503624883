const utilService = (() => {
    function debounce(func, delay) {
        let timeoutId;

        return function (...args) {
            clearTimeout(timeoutId);

            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    }

    return {
        debounce,
    };
})();

export default utilService;
