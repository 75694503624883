import React from 'react';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { EntityType } from '../../../../types.generated';
import { useEntityRegistry } from '../../../useEntityRegistry';
import { IconStyleType } from '../../Entity';
import { CustomAvatar } from '../../../shared/avatar';
import SearchTextHighlighter from '../../../search/matches/SearchTextHighlighter';

const PlatformInfo = styled.div`
    margin-bottom: 16px;
    display: flex;
    align-items: center;
`;

const PreviewImage = styled.div`
    max-height: 18px;
    width: auto;
    object-fit: contain;
    margin-right: 10px;
    background-color: transparent;
`;

const PlatformDivider = styled.div`
    display: inline-block;
    padding-left: 10px;
    margin-right: 10px;
    border-right: 1px solid #e9e9ea;
    height: 21px;
    vertical-align: text-top;
`;

export const Preview = ({
    urn,
    name,
    title,
    photoUrl,
}: {
    urn: string;
    name: string;
    photoUrl?: string | undefined;
    title?: string | undefined;
}): JSX.Element => {
    const entityRegistry = useEntityRegistry();
    const url = entityRegistry.getEntityUrl(EntityType.CorpUser, urn);

    return (
        <div>
            <Link to={url}>
                <PlatformInfo>
                    <PreviewImage>
                        {entityRegistry.getIcon(EntityType.CorpUser, 20, IconStyleType.HIGHLIGHT)}
                    </PreviewImage>
                    <Typography.Text type="secondary" className="f-text-medium-content">
                        {name || urn}
                    </Typography.Text>
                    <PlatformDivider />
                    <Typography.Text type="secondary" className="f-text-medium-content">
                        {entityRegistry.getEntityName(EntityType.CorpUser)}
                    </Typography.Text>
                </PlatformInfo>
                {title && (
                    <Typography.Text className="f-text-medium-content f-color-dark-black-s80">
                        <SearchTextHighlighter field="title" text={title} />
                    </Typography.Text>
                )}
                <div className="mt-3">
                    <CustomAvatar size={28} photoUrl={photoUrl} name={name || undefined} />
                </div>
            </Link>
        </div>
    );
};
