import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Layout } from 'antd';
import { HomePage } from './home/HomePage';
import { SearchRoutes } from './SearchRoutes';
import { ManageIngestionPage } from './ingest/ManageIngestionPage';
import EmbeddedPage from './embed/EmbeddedPage';
import { PageRoutes } from '../conf/Global';
import { useEntityRegistry } from './useEntityRegistry';
import AppProviders from './AppProviders';
import EmbedLookup from './embed/lookup';

const { Content } = Layout;

/**
 * Container for all views behind an authentication wall.
 */
export const ProtectedRoutes = (): JSX.Element => {
    const entityRegistry = useEntityRegistry();

    return (
        <AppProviders>
            <Layout style={{ height: '100%', width: '100%' }}>
                <Layout>
                    {/* Content is added for PRIV-70538 ticket */}
                    <Content>
                        <Switch>
                            <Route exact path="/" render={() => <HomePage />} />
                            {/* Pages in which we don't need header section */}
                            <Route path={PageRoutes.INGESTION} render={() => <ManageIngestionPage />} />
                            <Route exact path={PageRoutes.EMBED_LOOKUP} render={() => <EmbedLookup />} />
                            {/* This is newly added by DH team */}
                            {entityRegistry.getEntities().map((entity) => (
                                <Route
                                    key={`${entity.getPathName()}/${PageRoutes.EMBED}`}
                                    path={`${PageRoutes.EMBED}/${entity.getPathName()}/:urn`}
                                    render={() => <EmbeddedPage entityType={entity.type} />}
                                />
                            ))}
                            {/* Pages in which we need header section */}
                            <Route path="/*" render={() => <SearchRoutes />} />
                        </Switch>
                    </Content>
                </Layout>
            </Layout>
        </AppProviders>
    );
};
