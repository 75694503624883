import { ArrowRightOutlined } from '@ant-design/icons';
import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'antd';
import styled from 'styled-components';
import { Domain, EntityType, RecommendationContent } from '../../../../types.generated';
import { LogoCountCard } from '../../../shared/LogoCountCard';
import { useEntityRegistry } from '../../../useEntityRegistry';
import DomainIcon from '../../../domain/DomainIcon';
import { PageRoutes } from '../../../../conf/Global';

const DomainListContainer = styled.div``;

const AllDomainsWrapper = styled.div`
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.styles['primary-color']};
    font-size: 14px;
`;

const AllDomainsText = styled.div`
    margin-right: 8px;
`;

const NUM_DOMAIN_CARDS = 9;

type Props = {
    content: Array<RecommendationContent>;
    onClick?: (index: number) => void;
};

export const DomainSearchList = ({ content, onClick }: Props) => {
    const entityRegistry = useEntityRegistry();

    const domainsWithCounts: Array<{ domain: Domain; count?: number }> = content
        .map((cnt) => ({ domain: cnt.entity, count: cnt.params?.contentParams?.count }))
        .filter((domainWithCount) => domainWithCount?.domain !== null)
        .slice(0, NUM_DOMAIN_CARDS) as Array<{
        domain: Domain;
        count?: number;
    }>;

    return (
        <DomainListContainer>
            <Card className="Domains">
                {domainsWithCounts.map((domain, index) => (
                    <Link
                        tabIndex={-1}
                        to={entityRegistry.getEntityUrl(EntityType.Domain, domain.domain.urn)}
                        onClick={() => onClick?.(index)}
                    >
                        <LogoCountCard
                            name={entityRegistry.getDisplayName(EntityType.Domain, domain.domain)}
                            logoComponent={
                                <DomainIcon
                                    style={{
                                        fontSize: 16,
                                        color: '#BFBFBF',
                                    }}
                                    ariaLabel={entityRegistry.getDisplayName(EntityType.Domain, domain.domain)}
                                />
                            }
                            count={domain.count}
                        />
                    </Link>
                ))}
                <Link to={PageRoutes.DOMAINS}>
                    <AllDomainsWrapper className="f-color-high-contrast" aria-label="View All Domains">
                        <AllDomainsText>View All Domains</AllDomainsText>
                        <ArrowRightOutlined />
                    </AllDomainsWrapper>
                </Link>
            </Card>
        </DomainListContainer>
    );
};
