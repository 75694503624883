import React from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';
import { RoutedTabs } from '../shared/RoutedTabs';
import { ManagePolicies } from './policy/ManagePolicies';
// import { ManageRoles } from './roles/ManageRoles';

const PageContainer = styled.div`
    padding-top: 16px;
    width: 100%;
`;

const PageHeaderContainer = styled.div`
    && {
        padding-left: 24px;
    }
`;

const Content = styled.div`
    &&& .ant-tabs-nav {
        margin: 0;
        margin-bottom: 8px;
    }
    color: #262626;

    &&& .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    }
`;

enum TabType {
    Roles = 'Roles',
    Policies = 'Policies',
}
const ENABLED_TAB_TYPES = [TabType.Roles, TabType.Policies];

export const ManagePermissions = () => {
    /**
     * Determines which view should be visible: roles or policies.
     */

    const getTabs = () => {
        return [
            /* TODO: commented ManageRoles for now */

            // {
            //     name: TabType.Roles,
            //     path: TabType.Roles.toLocaleLowerCase(),
            //     content: <ManageRoles />,
            //     display: {
            //         enabled: () => true,
            //     },
            // },
            {
                name: TabType.Policies,
                path: TabType.Policies.toLocaleLowerCase(),
                content: <ManagePolicies />,
                display: {
                    enabled: () => true,
                },
            },
        ].filter((tab) => ENABLED_TAB_TYPES.includes(tab.name));
    };

    const defaultTabPath = getTabs() && getTabs()?.length > 0 ? getTabs()[0].path : '';
    const onTabChange = () => null;

    return (
        <PageContainer>
            <PageHeaderContainer>
                <Typography.Text className="page-heading">Manage Permissions</Typography.Text>
                <Typography.Paragraph type="secondary" className="mt-2">
                    View your Data Catalog permissions. Take administrative actions.
                </Typography.Paragraph>
            </PageHeaderContainer>
            <Content>
                <RoutedTabs defaultPath={defaultTabPath} tabs={getTabs()} onTabChange={onTabChange} />
            </Content>
        </PageContainer>
    );
};
