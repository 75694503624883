import React, { useState } from 'react';
import GlossarySearch from './GlossarySearch';
import GlossaryBrowser from './GlossaryBrowser/GlossaryBrowser';
import { ProfileSidebarResizer } from '../entity/shared/containers/profile/sidebar/ProfileSidebarResizer';
import { SidebarWrapper } from '../shared/sidebar/components';

export const MAX_BROWSER_WIDTH = 500;
export const MIN_BROWSWER_WIDTH = 200;

export default function GlossarySidebar() {
    const [browserWidth, setBrowserWith] = useState(224);

    return (
        <>
            <SidebarWrapper
                width={browserWidth}
                className="f-width-100-xs f-mb-2-xs"
                data-testid="glossary-browser-sidebar"
            >
                <GlossarySearch />
                <GlossaryBrowser openToEntity />
            </SidebarWrapper>
            <ProfileSidebarResizer
                setSidePanelWidth={(width) =>
                    setBrowserWith(Math.min(Math.max(width, MIN_BROWSWER_WIDTH), MAX_BROWSER_WIDTH))
                }
                initialSize={browserWidth}
                isSidebarOnLeft
            />
        </>
    );
}
