import React from 'react';
import { Tag } from 'antd';
import styled from 'styled-components';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { ReactComponent as BuildIcon } from '../../../../../../images/icon-build.svg';

const ActivityTag = styled(Tag)`
    && {
        margin: 0;
        border-radius: 2px;
    }

    &.ant-tag-success {
        color: #70b246 !important;
        background-color: #eff6ea;
    }
    &.ant-tag-processing {
        color: #1ca8dd !important;
        background-color: #e8f6fc;
    }
    &.ant-tag-error {
        color: #d96758 !important;
        background-color: #fbeeec;
    }
`;

export enum ActivityStatus {
    ADD = 'ADD',
    MODIFY = 'MODIFY',
    REMOVE = 'REMOVE',
    HARD_DELETE = 'HARD_DELETE',
    SOFT_DELETE = 'SOFT_DELETE',
    CREATE = 'CREATE',
    REINSTATE = 'REINSTATE',
    STARTED = 'STARTED',
    COMPLETED = 'COMPLETED',
}

const ActivityTagColumn = ({ activity }: { activity: ActivityStatus }) => {
    let attributes = {
        icon: <PlusCircleOutlined />,
        className: '',
        color: 'success',
    };
    if ([ActivityStatus.MODIFY, ActivityStatus.REINSTATE, ActivityStatus.STARTED].includes(activity)) {
        attributes = {
            icon: <BuildIcon className="anticon" style={{ width: 14, height: 14 }} />,
            className: 'anticon',
            color: 'processing',
        };
    } else if ([ActivityStatus.REMOVE, ActivityStatus.SOFT_DELETE, ActivityStatus.HARD_DELETE].includes(activity)) {
        attributes = {
            icon: <MinusCircleOutlined />,
            className: '',
            color: 'error',
        };
    }
    return (
        <ActivityTag icon={attributes.icon} className={attributes.className} color={attributes.color}>
            {activity}
        </ActivityTag>
    );
};

export default ActivityTagColumn;
