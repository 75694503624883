import React, { useState } from 'react';
import styled from 'styled-components';
import { Col } from 'antd';
import { ANTD_GRAY } from '../../../constants';
import QueryCardHeader from './QueryCardHeader';
import QueryCardQuery from './QueryCardQuery';
import QueryCardDetails from './QueryCardDetails';

const Card = styled.div`
    width: 100%;
    border-radius: 4px;
    border: 1px solid ${ANTD_GRAY[5]};
    box-shadow: ${(props) => props.theme.styles['box-shadow']};
`;

export type Props = {
    urn?: string;
    query: string;
    title?: string;
    description?: string;
    createdAtMs?: number;
    showDelete?: boolean;
    showEdit?: boolean;
    showDetails?: boolean;
    onDeleted?: () => void;
    onClickExpand?: () => void;
    onClickEdit?: () => void;
    index?: number;
};

export default function QueryCard({
    urn,
    query,
    title,
    description,
    createdAtMs,
    showDelete,
    showEdit,
    showDetails = true,
    onDeleted,
    onClickExpand,
    onClickEdit,
    index,
}: Props) {
    const [focused, setFocused] = useState(false);

    return (
        <Col xs={24} md={12} lg={8}>
            <Card onMouseEnter={() => setFocused(true)} onMouseLeave={() => setFocused(false)}>
                <QueryCardHeader query={query} focused={focused} onClickExpand={onClickExpand} />
                <QueryCardQuery query={query} showDetails={showDetails} onClickExpand={onClickExpand} index={index} />
                {showDetails && (
                    <QueryCardDetails
                        urn={urn}
                        title={title}
                        description={description}
                        createdAtMs={createdAtMs}
                        showEdit={showEdit}
                        showDelete={showDelete}
                        onDeleted={onDeleted}
                        onClickEdit={onClickEdit}
                        onClickExpand={onClickExpand}
                        index={index}
                    />
                )}
            </Card>
        </Col>
    );
}
