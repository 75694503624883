import React, { useEffect } from 'react';

const ACCESSIBILITY_LOADED = 'ACCESSIBILITY_LOADED';

// This component listens for messages from the parent window to toggle high contrast mode.
const AccessibilityHandler: React.FC = ({ children }: any) => {
    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            if (event.data.type === ACCESSIBILITY_LOADED) {
                const { isAccessibilityOn } = event.data;
                if (isAccessibilityOn) {
                    document.body.classList.add('f-high-contrast');
                } else {
                    document.body.classList.remove('f-high-contrast');
                }
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    return <>{children}</>;
};

export default AccessibilityHandler;
