import React, { useEffect } from 'react';
import { Space, Card, Input } from 'antd';

import { SearchOutlined } from '@ant-design/icons';
import { StyledTable, TablePagination } from '../../components/styled/StyledTable';
import { useAssetAuditRetrievalQuery } from '../../../../../graphql/assetAudit.generated';
import { columns } from './TableColumns';
import FilterDropdown from '../../components/FilterDropdown/FilterDropdown';
import useTableRequest from '../../../../hooks/useTableRequest/useTableRequest';
import utilService from '../../../../../utils/util.service';
import { FilterOptions, OperatorType } from './FilterOptions/FilterOptions';

const { debounce } = utilService;

interface ChangeLogsProps {
    urn: string;
}

const ChangeLogs = ({ urn }: ChangeLogsProps) => {
    const {
        tableRequest,
        hasNextPage,
        hasPrevPage,
        onApplyFilter,
        onResetFilter,
        setTablePagination,
        fetchNextPage,
        fetchPrevPage,
        renderTextPagination,
    } = useTableRequest({
        urn,
        filters: [],
        orderBy: '-time',
        pagination: {
            limit: 10,
            offset: 0,
            totalRecords: 0,
        },
    });

    const {
        data: queryData,
        loading,
        refetch,
    } = useAssetAuditRetrievalQuery({
        variables: {
            input: tableRequest,
        },
    });

    useEffect(() => {
        refetch({ input: tableRequest });
    }, [tableRequest, refetch]);

    useEffect(() => {
        setTablePagination((prev) => ({
            ...prev,
            totalRecords: queryData?.assetAuditRetrieval?.totalResults ?? 0,
        }));
    }, [queryData?.assetAuditRetrieval?.totalResults, setTablePagination]);

    const onSearch = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
        const text: string = event.target.value;
        if (text) {
            onApplyFilter('message', text, OperatorType.CONTAIN);
        } else {
            onResetFilter('message');
        }
    }, 500);

    return (
        <Card>
            <Space.Compact block className="px-3 py-2">
                <Input
                    placeholder="Search by message"
                    suffix={<SearchOutlined className="site-form-item-icon" />}
                    onChange={onSearch}
                    allowClear
                />
            </Space.Compact>
            <Space className="px-2 py-3 f-layout-wrap-xs" size="small">
                {FilterOptions.map((item) => (
                    <FilterDropdown
                        {...item}
                        key={item.id}
                        onApplyFilter={onApplyFilter}
                        onResetFilter={onResetFilter}
                    />
                ))}
            </Space>
            <StyledTable
                loading={loading}
                columns={columns}
                dataSource={queryData?.assetAuditRetrieval?.result || []}
                rowKey="urn"
                pagination={false}
            />
            <TablePagination
                pagination={{
                    fetchNextPage,
                    fetchPrevPage,
                    hasNextPage,
                    hasPrevPage,
                    renderTextPagination,
                }}
            />
        </Card>
    );
};

export default ChangeLogs;
