import React from 'react';
import { useGetBrowsePathsQuery } from '../../../../../../graphql/browse.generated';
import { EntityType } from '../../../../../../types.generated';
import { useEntityRegistry } from '../../../../../useEntityRegistry';
import { ProfileNavBrowsePath } from './ProfileNavBrowsePath';

type Props = {
    urn: string;
    entityType: EntityType;
};

export const EntityProfileNavBar = ({ urn, entityType }: Props) => {
    const { data: browseData } = useGetBrowsePathsQuery({
        variables: { input: { urn, type: entityType } },
        fetchPolicy: 'cache-first',
    });
    const entityRegistry = useEntityRegistry();

    // TODO: Data products are not browsable for now until its listing page is not implemented
    const isDataProduct = entityType === EntityType.DataProduct;

    const isBrowsable = entityRegistry.getBrowseEntityTypes().includes(entityType) && !isDataProduct;

    return (
        <ProfileNavBrowsePath
            urn={urn}
            breadcrumbLinksEnabled={isBrowsable}
            type={entityType}
            path={browseData?.browsePaths?.[0]?.path || []}
        />
    );
};
