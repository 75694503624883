import React from 'react';
import { Checkbox, Space } from 'antd';
import { FolderOutlined, RightOutlined } from '@ant-design/icons';
import styled from 'styled-components/macro';
import { Maybe } from 'graphql/jsutils/Maybe';
import { Link } from 'react-router-dom';
import { EntityType } from '../../types.generated';
import { ANTD_GRAY } from '../entity/shared/constants';
import { useEntityRegistry } from '../useEntityRegistry';

const ItemWrapper = styled.div`
    transition: 0.15s;
`;

const GlossaryItem = styled.div`
    align-items: center;
    border-bottom: 1px solid ${ANTD_GRAY[4]};
    color: #262626;
    display: flex;
    font-size: 14px;
    font-weight: 700;
    justify-content: space-between;
    line-height: 22px;
    margin: 0 20px;
    padding: 16px 0;

    > span {
        display: flex;
        align-items: center;
    }

    .anticon-folder {
        margin-right: 8px;
    }
`;

const StyledRightOutline = styled(RightOutlined)`
    color: ${ANTD_GRAY[7]};
    font-size: 12px;
    margin-right: 20px;
`;

const CountWrapper = styled.span``;

const GlossaryName = styled.span`
    word-break: break-word;
`;

interface Props {
    name: string;
    urn: string;
    type: EntityType;
    selectedItems?: string[];
    count?: Maybe<number>;
    handleItemCheck: (event, name) => void;
}

function GlossaryEntityItem(props: Props) {
    const { name, urn, type, count, selectedItems, handleItemCheck } = props;

    const entityRegistry = useEntityRegistry();

    return (
        <Link to={`${entityRegistry.getEntityUrl(type, urn)}`}>
            <ItemWrapper>
                <GlossaryItem>
                    <Space size="middle" align="center" className="pr-4">
                        <Checkbox
                            checked={selectedItems?.includes(urn)}
                            onClick={(event) => handleItemCheck(event, name)}
                            aria-label={`Select ${name}`}
                        />
                        {type === EntityType.GlossaryNode && <FolderOutlined className="f-color-blue-s100" />}
                        <GlossaryName className="f-text-medium-content f-text-semibold f-color-dark-black-s80">
                            {name}
                        </GlossaryName>
                        <CountWrapper className="f-text-content f-text-normal f-color-dark-black-s50 pl-2">
                            {count}
                        </CountWrapper>
                    </Space>
                    {type === EntityType.GlossaryNode && <StyledRightOutline />}
                </GlossaryItem>
            </ItemWrapper>
        </Link>
    );
}

export default GlossaryEntityItem;
