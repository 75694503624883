enum ImageFormat {
    SVG = 'svg',
    PNG = 'png',
}
class ExportLineageAsImageFromSVG {
    Element: Node | SVGSVGElement | undefined;

    private StringSVG: string | undefined = '';

    // These are the nodes which have data-id='' then we want to remove from the SVG
    private NodeRemove: Array<string> = ['lineage-header', 'lineage-menu', 'lineage-pagination'];

    private Image: HTMLImageElement = new Image();

    constructor(public ElementPath: string | undefined = '') {}

    async init() {
        // here we find the DOM element.
        this.Element = document.querySelector(this.ElementPath as string)?.cloneNode(true) as SVGSVGElement;

        // If the element is not found, throw an error.
        if (!this.Element) {
            console.error('Could not find the element');
            return;
        }

        // Here, we select the images tag inside SVG then convert it into base64.
        const tempSvg: SVGAElement = await this.selectImagesInsideSVG();

        // Here, we remove the unnecessary icon from the images
        this.removeIconFromImage(tempSvg);

        // Updated SVG string with modified nodes
        this.StringSVG = new XMLSerializer().serializeToString(tempSvg);
    }

    downloadAsImage = () => {
        // Set the image source to the base64
        this.Image.src = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(this.StringSVG as string)}`;

        // Wait for the image to load
        this.Image.onload = () => {
            // Create a canvas and draw the SVG image
            const canvas = document.createElement('canvas');

            // Here, we set the width and height of the image in the canvas.
            canvas.width = this.Image.width;
            canvas.height = this.Image.height;

            // Here, we create the 2D context of the canvas and then generate the image on the canvas.
            const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
            ctx.drawImage(this.Image, 0, 0);

            // Here, we set the DPI and some other properties related to image processing.
            ctx.scale(2000, 2000);
            ctx.imageSmoothingEnabled = true;
            ctx.imageSmoothingQuality = 'high';

            // Here, we download the PNG image.
            this.createDownloadableTag(canvas.toDataURL('image/png', 1), 'lineage_image', ImageFormat.PNG);
        };
    };

    downloadAsSvg = () => {
        // Here, we create the blob of SVG.
        const blob = new Blob([this.StringSVG as string], { type: 'image/svg+xml;charset=utf-8' });

        // Here, we download the SVG image from the created blob.
        this.createDownloadableTag(blob, 'lineage_svg', ImageFormat.SVG);
    };

    private createDownloadableTag = (
        media: Blob | string,
        name: string | undefined = '',
        imageFormat: ImageFormat = ImageFormat.SVG,
    ) => {
        // Create the anchor tag to download the image.
        const downloadLink = document.createElement('a');

        // Attach the link to the anchor tag.
        downloadLink.href = typeof media === 'string' ? media : URL.createObjectURL(media);
        downloadLink.download = `${name}.${imageFormat}`;

        // Append the link to the body and trigger a click to download the image.
        document.body.appendChild(downloadLink);
        downloadLink.click();

        // Remove the link from the body
        document.body.removeChild(downloadLink);
    };

    // This method is used to remove unnecessary icons from the image.
    private removeIconFromImage = (element: SVGAElement) => {
        this.NodeRemove.forEach((id) => {
            // Get all elements with the specified data-id
            const elementsToRemove = element.querySelectorAll(`[data-id=${id}]`);

            // Convert the NodeList to an array for easier manipulation
            Array.from(elementsToRemove).forEach((el) => {
                // Remove each element
                el.remove();
            });
        });
    };

    // This method is used to inject the base64 URL into the image.
    private selectImagesInsideSVG = async (): Promise<SVGAElement> => {
        // Create an SVG element for parsing
        const tempSvg = this.Element as SVGAElement;

        // Select all image elements
        const imageElements = tempSvg.querySelectorAll('image');

        // Iterate through image elements and replace with base64 data URL
        return Promise.all(Array.from(imageElements).map(this.imageToBase64))
            .then((base64Strings) => {
                imageElements.forEach((imageElement, index) => {
                    imageElement.setAttribute('href', `data:image/png;base64,${base64Strings[index]}`);
                });

                // Here, we return the modified Node Svg
                return tempSvg;
            })
            .catch((error) => {
                console.error(error);
                return tempSvg;
            });
    };

    // Function to convert an image to base64
    private imageToBase64 = async (imgElement) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = 'Anonymous';
            img.src = imgElement.getAttribute('href'); // Assuming the href attribute contains the image URL
            img.onload = function () {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
                ctx.drawImage(img, 0, 0);
                const base64String = canvas.toDataURL('image/png').split(',')[1];
                resolve(base64String);
            };
            img.onerror = function () {
                reject(new Error('Failed to load image.'));
            };
        });
    };
}

export default ExportLineageAsImageFromSVG;
