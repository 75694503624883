import styled from 'styled-components';

export const ChartContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 768px) {
        overflow-x: auto;
    }
`;
