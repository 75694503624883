import React from 'react';
import { Tag, Typography } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { EntityType } from '../../../../types.generated';
import { useEntityRegistry } from '../../../useEntityRegistry';
import { IconStyleType } from '../../Entity';
import NoMarkdownViewer from '../../shared/components/styled/StripMarkdownText';
import SearchTextHighlighter from '../../../search/matches/SearchTextHighlighter';

const PlatformInfo = styled.div`
    margin-bottom: 16px;
    display: flex;
    align-items: center;
`;

const PreviewImage = styled.div`
    max-height: 18px;
    width: auto;
    object-fit: contain;
    margin-right: 10px;
    background-color: transparent;
`;

const PlatformDivider = styled.div`
    display: inline-block;
    padding-left: 10px;
    margin-right: 10px;
    border-right: 1px solid #e9e9ea;
    height: 21px;
    vertical-align: text-top;
`;

export const Preview = ({
    urn,
    name,
    description,
    membersCount,
}: {
    urn: string;
    name: string;
    description?: string | null;
    membersCount?: number;
}): JSX.Element => {
    const entityRegistry = useEntityRegistry();
    const url = entityRegistry.getEntityUrl(EntityType.CorpGroup, urn);

    return (
        <div>
            <Link to={url}>
                <PlatformInfo>
                    <PreviewImage>
                        {entityRegistry.getIcon(EntityType.CorpGroup, 20, IconStyleType.HIGHLIGHT)}
                    </PreviewImage>
                    <Typography.Text type="secondary" className="f-text-medium-content">
                        {name || urn}
                    </Typography.Text>
                    <PlatformDivider />
                    <Typography.Text type="secondary" className="f-text-medium-content">
                        {entityRegistry.getEntityName(EntityType.CorpGroup)}
                    </Typography.Text>
                </PlatformInfo>
                {name ? <SearchTextHighlighter field="name" text={name} /> : urn}
                <Tag>{membersCount} members</Tag>
            </Link>
            {description && description.length > 0 && (
                <div className="mt-2">
                    <NoMarkdownViewer
                        limit={200}
                        customRender={(text) => <SearchTextHighlighter field="description" text={text} />}
                    >
                        {description}
                    </NoMarkdownViewer>
                </div>
            )}
        </div>
    );
};
