import React from 'react';
import { Row } from 'antd';
import Query from './Query';
import { Query as QueryType } from './types';

type Props = {
    queries: QueryType[];
    showDetails?: boolean;
    showEdit?: boolean;
    showDelete?: boolean;
    onDeleted?: (query) => void;
    onEdited?: (newQuery) => void;
};

export default function QueriesList({
    queries,
    showEdit = true,
    showDelete = true,
    showDetails = true,
    onDeleted,
    onEdited,
}: Props) {
    return (
        <Row gutter={[16, 16]}>
            {queries.map((query, idx) => (
                <Query
                    urn={query.urn}
                    title={query.title || undefined}
                    description={query.description || undefined}
                    query={query.query}
                    createdAtMs={query.createdTime}
                    showDelete={showDelete}
                    showEdit={showEdit}
                    showDetails={showDetails}
                    onDeleted={() => onDeleted?.(query)}
                    onEdited={(newQuery) => onEdited?.(newQuery)}
                    index={idx}
                />
            ))}
        </Row>
    );
}
