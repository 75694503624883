import React from 'react';
import { Divider, Popover, Tag, Tooltip, Typography, message } from 'antd';
import styled from 'styled-components';
import moment from 'moment';

import { useRemoveGovernanceFlagsAspectMutation } from '../../../../../graphql/mutations.generated';
import { useListUsersQuery } from '../../../../../graphql/user.generated';
import { getLocaleTimezone } from '../../../../shared/time/timeUtils';
import { ANTD_GRAY } from '../../constants';

const StatusTitle = styled(Typography.Text)`
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: bold;
`;

const StatusSubTitle = styled(Typography.Text)`
    display: block;
    margin-bottom: 5px;
`;

const LastEvaluatedAtLabel = styled.div`
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    color: ${ANTD_GRAY[7]};
`;

const ThinDivider = styled(Divider)`
    margin-top: 8px;
    margin-bottom: 8px;
`;

type Props = {
    urn: string;
    aspectFlag: any;
    closable?: boolean;
    refetch?: () => void;
};

/**
 * This component is used to display the governance flag batch for the entity profile page header section
 * @param urn - urn of the entity
 * @param refetch - function to refetch the data
 * @param aspectFlag - aspect flag data
 */
export const GovernanceFlagAspectBatch = ({ urn, refetch, aspectFlag, closable = true }: Props) => {
    const localeTimezone = getLocaleTimezone();
    const [removeGovernanceFlag] = useRemoveGovernanceFlagsAspectMutation();

    // Fetch the list of users to display the actor name
    const { data } = useListUsersQuery({
        variables: {
            input: {
                query: '*',
                start: 0,
                count: 1000,
            },
        },
    });

    // Get the list of users from the data
    const users = data?.listUsers?.users || [];

    const hasDetails = aspectFlag.doc !== '';

    // Function to remove the governance flag batch from the entity
    const removeBatch = async (flag) => {
        try {
            await removeGovernanceFlag({
                variables: {
                    urn,
                    input: {
                        name: flag.name,
                    },
                },
            });
            message.destroy();
            message.success({ content: 'Flag removed successfully', duration: 2 });
        } catch (e: unknown) {
            message.destroy();
        }
        refetch?.();
    };

    return aspectFlag?.flags.map((flag: any) => {
        // Find the actor name from the list of users
        const actor = users?.find((user) => user.urn === flag?.auditStamp?.actor);

        // If the actor is not found, use the username
        const userName = actor?.info?.fullName || actor?.username;

        return (
            <Popover
                className="ml-1"
                placement="right"
                overlayStyle={{ maxWidth: 240, zIndex: 1071 }}
                content={
                    hasDetails ? (
                        <>
                            <StatusTitle>Governance Aspect Batch</StatusTitle>
                            <ThinDivider />
                            <StatusSubTitle>{flag.doc}</StatusSubTitle>
                            <Typography.Text type="secondary">
                                <Tooltip
                                    placement="right"
                                    title={moment(flag.auditStamp.time).utc().format('dddd, DD/MMM/YYYY HH:mm:ss z')}
                                >
                                    <LastEvaluatedAtLabel>{`Time: ${moment(flag.auditStamp.time).format(
                                        'DD/MMM/YYYY',
                                    )} (${localeTimezone})`}</LastEvaluatedAtLabel>
                                </Tooltip>
                            </Typography.Text>
                            <StatusSubTitle type="secondary">User: {userName || ''}</StatusSubTitle>
                        </>
                    ) : (
                        'No additional details'
                    )
                }
            >
                <Tag
                    closable={closable}
                    className="pri-tag"
                    style={{ height: 'fit-content' }} // For default PreviewCard in search page
                    onClose={(e) => {
                        e.preventDefault(); // Prevent the default close action
                        removeBatch(flag);
                    }}
                >
                    {flag.name}
                </Tag>
            </Popover>
        );
    });
};
