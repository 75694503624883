import React from 'react';
import { Image, Typography } from 'antd';
import styled from 'styled-components';
import { formatNumber } from './formatNumber';
import { HomePageButton } from './components';

const PlatformLogo = styled(Image)`
    max-height: 32px;
    width: auto;
    object-fit: contain;
    background-color: transparent;
    padding-right: 10px;
`;

const CountText = styled(Typography.Text)``;

const LogoContainer = styled.div``;

const TileContent = styled.div``;

const Title = styled(Typography.Title)`
    word-break: break-word;
`;

type Props = {
    logoUrl?: string;
    logoComponent?: React.ReactNode;
    name: string;
    isIngestion?: boolean;
    count?: number;
    onClick?: () => void;
};

export const LogoCountCard = ({ logoUrl, logoComponent, name, count, onClick, isIngestion }: Props) => {
    return (
        <HomePageButton
            isIngestion={isIngestion || false}
            className="stats-tile-card mb-0"
            onClick={onClick}
            tabIndex={0}
            aria-label={`${name} ${formatNumber(count)}`}
        >
            <LogoContainer className="tile-logo">
                {(logoUrl && <PlatformLogo preview={false} src={logoUrl} alt={name} />) || logoComponent}
            </LogoContainer>
            <TileContent className="tile-content">
                {count !== undefined && <CountText className="tile-count">{formatNumber(count)}</CountText>}
                <Title
                    ellipsis={{
                        rows: 4,
                    }}
                    level={5}
                    className="tile-title"
                >
                    {name}
                </Title>
            </TileContent>
        </HomePageButton>
    );
};
