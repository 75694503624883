import React from 'react';
import styled from 'styled-components/macro';
import { useHistory, useLocation } from 'react-router-dom';
import { Col, Button, Space, Badge } from 'antd';
import { InfoCircleOutlined, PartitionOutlined } from '@ant-design/icons';
import { EntityType } from '../../../../../../types.generated';
import { navigateToLineageUrl } from '../../../../../lineage/utils/navigateToLineageUrl';
import { ENTITY_TYPES_WITH_MANUAL_LINEAGE } from '../../../constants';
import useIsLineageMode from '../../../../../lineage/utils/useIsLineageMode';
import { useGetLineageTimeParams } from '../../../../../lineage/utils/useGetLineageTimeParams';
import { useIsSeparateSiblingsMode } from '../../../siblingUtils';
import { useGetLineageCountsQuery } from '../../../../../../graphql/lineage.generated';
import useIsChangeLogMode from '../utils/useIsChangeLogMode';
import { ReactComponent as ChangeLogIcon } from '../../../../../../images/icon-change-log.svg';

import { ReactComponent as UpstreamIcon } from '../../../../../../images/icon-upstream.svg';
import { ReactComponent as DownstreamIcon } from '../../../../../../images/icon-downstream.svg';

const LineageSummary = styled.div`
    margin-left: 16px;
`;

const LineageBadge = styled(Badge)`
    display: flex;
    padding: 8px;
    background-color: #fff;
    border-radius: 32px;
`;

type Props = {
    urn: string;
    type: EntityType;
};

/**
 * Responsible for rendering a clickable browse path view.
 */
export const LineageSelector = ({ urn, type }: Props): JSX.Element => {
    const history = useHistory();
    const location = useLocation();
    const isLineageMode = useIsLineageMode();
    const isHideSiblingsMode = useIsSeparateSiblingsMode();
    const { startTimeMillis, endTimeMillis } = useGetLineageTimeParams();
    const isChangeLogMode = useIsChangeLogMode();

    // Fetch the lineage counts for the entity.
    const { data: lineageData, loading: lineageLoading } = useGetLineageCountsQuery({
        variables: {
            urn,
            separateSiblings: isHideSiblingsMode,
            startTimeMillis,
            endTimeMillis,
        },
    });

    const upstreamTotal = (lineageData?.entity as any)?.upstream?.total || 0;
    const upstreamFiltered = (lineageData?.entity as any)?.upstream?.filtered || 0;
    const upstreamCount = upstreamTotal - upstreamFiltered;

    const downstreamTotal = (lineageData?.entity as any)?.downstream?.total || 0;
    const downstreamFiltered = (lineageData?.entity as any)?.downstream?.filtered || 0;
    const downstreamCount = downstreamTotal - downstreamFiltered;

    const hasLineage = upstreamCount > 0 || downstreamCount > 0;
    const canNavigateToLineage = hasLineage || ENTITY_TYPES_WITH_MANUAL_LINEAGE.has(type);

    const upstreamText = upstreamCount === 100 ? '100+' : upstreamCount;
    const downstreamText = downstreamCount === 100 ? '100+' : downstreamCount;

    return (
        <>
            <Col flex="none">
                <Space size="middle" className="f-mt-3-xs">
                    <Button
                        type="primary"
                        icon={<ChangeLogIcon />}
                        className={`${isChangeLogMode ? 'f-btn-active' : ''} f-btn-underline`}
                        onClick={() => {
                            navigateToLineageUrl({ location, history, isLineageMode: false, isChangeLogMode: true });
                        }}
                    >
                        Change Log
                    </Button>
                    <Button
                        type="primary"
                        icon={<InfoCircleOutlined />}
                        className={`${!isLineageMode && !isChangeLogMode ? 'f-btn-active' : ''} f-btn-underline`}
                        disabled={!canNavigateToLineage}
                        onClick={() => {
                            if (canNavigateToLineage) {
                                navigateToLineageUrl({
                                    location,
                                    history,
                                    isLineageMode: false,
                                    startTimeMillis,
                                    endTimeMillis,
                                });
                            }
                        }}
                    >
                        Details
                    </Button>
                    <Button
                        type="primary"
                        icon={<PartitionOutlined />}
                        className={`${isLineageMode ? 'f-btn-active' : ''} f-btn-underline`}
                        disabled={!canNavigateToLineage}
                        onClick={() => {
                            if (canNavigateToLineage) {
                                navigateToLineageUrl({
                                    location,
                                    history,
                                    isLineageMode: true,
                                    startTimeMillis,
                                    endTimeMillis,
                                });
                            }
                        }}
                    >
                        Lineage
                    </Button>
                </Space>
            </Col>
            <Col flex="none" className="pl-3 f-pl-1-xs f-mt-3-xs">
                <LineageSummary>
                    <LineageBadge className="f-text-content f-color-dark-black-s50">
                        <UpstreamIcon className="mr-1" style={{ width: 16, height: 16 }} />
                        <div className="f-color-dark-black-s80 mr-1 ml-2">{lineageLoading ? '-' : upstreamText}</div>
                        <div>upstream</div>
                        <DownstreamIcon className="mr-1 ml-2" style={{ width: 16, height: 16 }} />
                        <div className="f-color-dark-black-s80 mr-1 ml-2">{lineageLoading ? '-' : downstreamText}</div>
                        <div>downstream</div>
                    </LineageBadge>
                </LineageSummary>
            </Col>
        </>
    );
};
