import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { ChildGlossaryTermFragment } from '../../graphql/glossaryNode.generated';
import { useExportEntityMutation } from '../../graphql/mutations.generated';
import { GlossaryNodeFragment } from '../../graphql/fragments.generated';
import { GlossaryNode, GlossaryTerm } from '../../types.generated';

import { BulkAction } from '../shared/bulkAction/BulkAction';
import { useEntityRegistry } from '../useEntityRegistry';
import GlossaryEntityItem from './GlossaryEntityItem';
import { Notification } from '../shared/Notification';
import { Message } from '../shared/Message';

const EntitiesWrapper = styled.div`
    flex: 1;
    padding: 4px;
    overflow: auto;
    background-color: #ffffff;

    a {
        display: block;
    }
`;

interface Props {
    nodes: (GlossaryNode | GlossaryNodeFragment)[];
    terms: (GlossaryTerm | ChildGlossaryTermFragment)[];
    selectedItems: string[];
    setSelectedItems: (items: string[]) => void;
}

function GlossaryEntitiesList(props: Props) {
    const { nodes, terms, selectedItems, setSelectedItems } = props;
    const entityRegistry = useEntityRegistry();

    const [isCsvExporting, setIsCsvExporting] = useState<boolean>(false);
    const [exportEntityMutation] = useExportEntityMutation();
    const [exportEntity, setExportEntity]: any = useState(null);

    const handleItemCheck = (event, glossary) => {
        event.preventDefault();
        event.stopPropagation();

        let updatedGlossaryUrns = [...selectedItems];

        if (updatedGlossaryUrns.includes(glossary.urn)) {
            updatedGlossaryUrns = updatedGlossaryUrns.filter((name) => name !== glossary.urn);
        } else {
            updatedGlossaryUrns.push(glossary.urn);
        }

        setSelectedItems(updatedGlossaryUrns);
    };

    /**
     * Handles the export of the selected datasets to a CSV file.
     * Calls the exportEntityMutation to export the selected datasets to a CSV file.
     * Displays a notification message to the user once the export is completed.
     * Clears the selectedItems state and sets the isAllSelected state to false.
     */
    const exportCsv = () => {
        const glossaryNodeUrns = nodes.filter((item) => selectedItems.includes(item?.urn)).map((item) => item?.urn);
        const glossaryTermUrns = terms.filter((item) => selectedItems.includes(item?.urn)).map((item) => item?.urn);

        setIsCsvExporting(true);
        setExportEntity(null);

        exportEntityMutation({
            variables: {
                input: {
                    glossaryTerm: {
                        glossaryNodeUrns,
                        glossaryTermUrns,
                    },
                    entityType: 'glossaryTerm',
                },
            },
        })
            .then((res = {}) => {
                const { data } = res;
                setExportEntity(data?.exportEntity);
                setSelectedItems([]);
                // setIsAllSelected(false);
            })
            .finally(() => setIsCsvExporting(false));
    };

    return (
        <>
            {exportEntity && <Notification exportEntity={exportEntity} />}

            {isCsvExporting && <Message type="loading" content="Loading..." style={{ marginTop: '10%' }} />}

            <EntitiesWrapper>
                {nodes.map((node) => (
                    <GlossaryEntityItem
                        name={node.properties?.name || ''}
                        urn={node.urn}
                        type={node.type}
                        selectedItems={selectedItems}
                        handleItemCheck={(e) => handleItemCheck(e, node)}
                        count={(node as GlossaryNodeFragment).children?.total}
                    />
                ))}
                {terms.map((term) => (
                    <GlossaryEntityItem
                        name={entityRegistry.getDisplayName(term.type, term)}
                        handleItemCheck={(e) => handleItemCheck(e, term)}
                        selectedItems={selectedItems}
                        urn={term.urn}
                        type={term.type}
                    />
                ))}
            </EntitiesWrapper>

            <BulkAction
                type="glossary"
                selectedItems={selectedItems}
                exportCsv={exportCsv}
                setSelectedItems={setSelectedItems}
            />
        </>
    );
}

export default GlossaryEntitiesList;
