import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { Typography, Row, Col, Button, Tag } from 'antd';
import styled, { useTheme } from 'styled-components';

import { RightOutlined } from '@ant-design/icons';
import { useEntityRegistry } from '../useEntityRegistry';
import { navigateToSearchUrl } from '../search/utils/navigateToSearchUrl';
import { SearchBar } from '../search/SearchBar';
import {
    GetAutoCompleteMultipleResultsQuery,
    useGetAutoCompleteMultipleResultsLazyQuery,
    useGetSearchResultsForMultipleQuery,
} from '../../graphql/search.generated';
import { EntityType, FacetFilterInput } from '../../types.generated';
import analytics, { EventType } from '../analytics';
import { HeaderLinks } from '../shared/admin/HeaderLinks';
import { HOME_PAGE_SEARCH_BAR_ID } from '../onboarding/config/HomePageOnboardingConfig';
import { useQuickFiltersContext } from '../../providers/QuickFiltersContext';
import { getAutoCompleteInputFromQuickFilter } from '../search/utils/filterUtils';
import { useUserContext } from '../context/useUserContext';

const Background = styled.div`
    width: 100%;
`;

// const WelcomeText = styled(Typography.Text)`
//     font-size: 24px;
//     line-height: 40px;
//     color: ${(props) =>
//         props.theme.styles['homepage-text-color'] || props.theme.styles['homepage-background-lower-fade']};
//     color: #4c4e54;
// `;

const styles = {
    pageHeader: { marginBottom: 0 },
    pageHeaderRow: { marginBottom: '24px' },
    searchContainer: { width: '100%' },
    searchBox: { maxWidth: '100%', margin: '0', marginBottom: '12px' },
    navBar: { padding: '24px' },
    logoImage: { width: 140 },
    subtitle: { marginTop: '28px', color: '#FFFFFF', fontSize: 12 },
};

const NavGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const SuggestionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: start;
`;

const SuggestionsHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const SuggestionTagContainer = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
    flex-wrap: wrap;
    > div {
        margin-bottom: 12px;
    }
`;

const SuggestionButton = styled(Button)`
    padding: 0px;
    margin-bottom: 16px;
`;

const SuggestedQueriesText = styled(Typography.Text)`
    margin-bottom: 12px;
`;

const SearchBarContainer = styled.div`
    text-align: center;
`;

const ExploreAllButton = styled(Button)`
    && {
        padding: 0px;
        margin: 0px;
        height: 16px;
    }
`;

const StyledRightOutlined = styled(RightOutlined)`
    &&& {
        font-size: 7px;
        margin-left: 4px;
        padding: 0px;
    }
`;

function truncate(input, length) {
    if (input.length > length) {
        return `${input.substring(0, length)}...`;
    }
    return input;
}

function sortRandom() {
    return 0.5 - Math.random();
}

export const HomePageHeader = () => {
    const history = useHistory();
    const entityRegistry = useEntityRegistry();
    const [getAutoCompleteResultsForMultiple, { data: suggestionsData }] = useGetAutoCompleteMultipleResultsLazyQuery();
    const userContext = useUserContext();
    const themeConfig = useTheme();
    // const appConfig = useAppConfig();
    const [newSuggestionData, setNewSuggestionData] = useState<GetAutoCompleteMultipleResultsQuery | undefined>();
    const { selectedQuickFilter } = useQuickFiltersContext();
    const viewUrn = userContext.localState?.selectedViewUrn;
    // const viewsEnabled = appConfig.config?.viewsConfig?.enabled || false;

    useEffect(() => {
        if (suggestionsData !== undefined) {
            setNewSuggestionData(suggestionsData);
        }
    }, [suggestionsData]);

    const onSearch = (query: string, type?: EntityType, filters?: FacetFilterInput[]) => {
        analytics.event({
            type: EventType.HomePageSearchEvent,
            query,
            pageNumber: 1,
            selectedQuickFilterTypes: selectedQuickFilter ? [selectedQuickFilter.field] : undefined,
            selectedQuickFilterValues: selectedQuickFilter ? [selectedQuickFilter.value] : undefined,
        });
        navigateToSearchUrl({
            type,
            query,
            history,
            filters,
        });
    };

    const onAutoComplete = (query: string) => {
        if (query && query.trim() !== '') {
            getAutoCompleteResultsForMultiple({
                variables: {
                    input: {
                        query,
                        limit: 10,
                        viewUrn,
                        ...getAutoCompleteInputFromQuickFilter(selectedQuickFilter),
                    },
                },
            });
        }
    };

    const onClickExploreAll = () => {
        analytics.event({
            type: EventType.HomePageExploreAllClickEvent,
        });
        navigateToSearchUrl({
            query: '*',
            history,
        });
    };

    // Fetch results
    const { data: searchResultsData } = useGetSearchResultsForMultipleQuery({
        variables: {
            input: {
                types: [],
                query: '*',
                start: 0,
                count: 6,
                filters: [],
                orFilters: [],
                viewUrn,
            },
        },
    });

    const searchResultsToShow = useMemo(() => {
        let result: string[] | undefined = [];
        if (searchResultsData) {
            const entities = searchResultsData?.searchAcrossEntities?.searchResults.map((searchResult) => {
                return searchResult?.entity;
            });

            result = entities?.map((entity) => {
                return entityRegistry.getDisplayName(entity.type, entity);
            });
        }
        return result?.sort(sortRandom);
    }, [searchResultsData, entityRegistry]);

    return (
        <Background>
            <Row justify="space-between" style={styles.pageHeaderRow} className="f-flex-column-xs">
                <Typography.Title className="page-heading" style={styles.pageHeader}>
                    Data Catalog
                </Typography.Title>
                <NavGroup>
                    <HeaderLinks />
                </NavGroup>
            </Row>
            <Row justify="center">
                <Col xs={24} lg={12}>
                    {!!themeConfig.content.subtitle && (
                        <Typography.Text style={styles.subtitle}>{themeConfig.content.subtitle}</Typography.Text>
                    )}
                    <SearchBarContainer id={HOME_PAGE_SEARCH_BAR_ID}>
                        <SearchBar
                            placeholderText={themeConfig.content.search.searchbarMessage}
                            suggestions={newSuggestionData?.autoCompleteForMultiple?.suggestions || []}
                            onSearch={onSearch}
                            onQueryChange={onAutoComplete}
                            autoCompleteStyle={styles.searchBox}
                            entityRegistry={entityRegistry}
                            // viewsEnabled={viewsEnabled}
                            combineSiblings
                            showQuickFilters
                        />
                        {searchResultsToShow && searchResultsToShow.length > 0 && (
                            <SuggestionsContainer>
                                <SuggestionsHeader>
                                    <SuggestedQueriesText className="f-text-content f-color-dark-black-s80">
                                        Try searching for
                                    </SuggestedQueriesText>
                                    <ExploreAllButton type="link" aria-label="Explore all" onClick={onClickExploreAll}>
                                        Explore all <StyledRightOutlined />
                                    </ExploreAllButton>
                                </SuggestionsHeader>

                                <SuggestionTagContainer>
                                    {searchResultsToShow.slice(0, 3).map((suggestion) => (
                                        <SuggestionButton
                                            key={suggestion}
                                            type="link"
                                            onClick={() =>
                                                navigateToSearchUrl({
                                                    type: undefined,
                                                    query: `"${suggestion}"`,
                                                    history,
                                                })
                                            }
                                        >
                                            <Tag className="pri-tag">{truncate(suggestion, 40)}</Tag>
                                        </SuggestionButton>
                                    ))}
                                </SuggestionTagContainer>
                            </SuggestionsContainer>
                        )}
                    </SearchBarContainer>
                </Col>
            </Row>
        </Background>
    );
};
