import React from 'react';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { EntityType, RecommendationContent, Tag } from '../../../../types.generated';
import { StyledTag } from '../../../entity/shared/components/styled/StyledTag';
import { UnionType } from '../../../search/utils/constants';
import { navigateToSearchUrl } from '../../../search/utils/navigateToSearchUrl';
import { useEntityRegistry } from '../../../useEntityRegistry';

const TagSearchListContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 16px 16px 8px;
`;

const TagContainer = styled.div`
    margin-bottom: 8px;
`;

const TagButton = styled(Button)`
    margin: 0px;
    padding: 0px;
    font-weight: 500;
`;

type Props = {
    content: Array<RecommendationContent>;
    onClick?: (index: number) => void;
};

export const TagSearchList = ({ content, onClick }: Props) => {
    const history = useHistory();
    const entityRegistry = useEntityRegistry();

    const tags: Array<Tag> = content
        .map((cnt) => cnt.entity)
        .filter((entity) => entity !== null && entity !== undefined)
        .map((entity) => entity as Tag);

    const onClickTag = (tag: any, index: number) => {
        onClick?.(index);
        navigateToSearchUrl({
            filters: [
                {
                    field: 'tags',
                    values: [tag.urn],
                },
                {
                    field: 'fieldTags',
                    values: [tag.urn],
                },
            ],
            history,
            unionType: UnionType.OR,
        });
    };

    return (
        <TagSearchListContainer>
            {tags.map((tag, index) => (
                <TagContainer key={tag.urn}>
                    <TagButton type="link" onClick={() => onClickTag(tag, index)}>
                        <StyledTag
                            className="pri-tag"
                            $colorHash={tag?.urn}
                            $color={tag?.properties?.colorHex}
                            closable={false}
                        >
                            {entityRegistry.getDisplayName(EntityType.Tag, tag)}
                        </StyledTag>
                    </TagButton>
                </TagContainer>
            ))}
        </TagSearchListContainer>
    );
};
