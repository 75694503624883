import React from 'react';
import LinkButton from '../LinkButton';
import { useEntityData, useRefetch } from '../../../../EntityContext';
import { AddLinkModal } from '../../../../components/styled/AddLinkModal';

interface Props {
    hideLinksButton?: boolean;
    readOnly?: boolean;
}

export default function LinksSection({ hideLinksButton, readOnly }: Props) {
    const { entityData } = useEntityData();
    const refetch = useRefetch();

    const links = entityData?.institutionalMemory?.elements || [];

    return (
        <>
            {links.map((link) => (
                <div className="mb-2">
                    <LinkButton link={link} />
                </div>
            ))}
            {!readOnly && !hideLinksButton && <AddLinkModal buttonProps={{ type: 'default' }} refetch={refetch} />}
        </>
    );
}
