import React from 'react';
import { Row, Typography, Tooltip } from 'antd';
import styled from 'styled-components';
import { CorpUser, Maybe, UserUsageCounts } from '../../../../../../../types.generated';
import { InfoItem } from '../../../../components/styled/InfoItem';
import { countFormatter } from '../../../../../../../utils/formatter/index';
import { ExpandedActorGroup } from '../../../../components/styled/ExpandedActorGroup';
import { formatNumberWithoutAbbreviation } from '../../../../../../shared/formatNumber';

type Props = {
    rowCount?: number;
    columnCount?: number;
    queryCount?: number;
    users?: Array<Maybe<UserUsageCounts>>;
    lastUpdatedTime?: string;
    lastReportedTime?: string;
};

const StatSection = styled.div`
    border-top: 1px solid #edf0f0;
    border-bottom: 1px solid #edf0f0;
`;

export default function TableStats({
    rowCount,
    columnCount,
    queryCount,
    users,
    lastUpdatedTime,
    lastReportedTime,
}: Props) {
    // If there are less than 4 items, simply stack the stat views.
    const justifyContent = !queryCount && !users ? 'center' : 'space-between';
    const lastReportedTimeString = lastReportedTime || 'unknown';
    if (
        !rowCount &&
        !columnCount &&
        !queryCount &&
        !(users && users.length > 0) &&
        !lastUpdatedTime &&
        !lastReportedTime
    ) {
        return null;
    }
    const sortedUsers = users?.slice().sort((a, b) => (b?.count || 0) - (a?.count || 0));
    return (
        <StatSection className="px-4 pt-3 pb-4">
            <Typography.Title className="f-text-medium-content f-text-semibold f-color-dark-black-s80 mb-4" level={5}>
                Table Stats
            </Typography.Title>
            <Row justify={justifyContent}>
                {rowCount && (
                    <InfoItem title="Rows">
                        <Tooltip title={formatNumberWithoutAbbreviation(rowCount)} placement="right">
                            <Typography.Text
                                strong
                                style={{ fontSize: 24 }}
                                data-testid="table-stats-rowcount"
                                className="f-text-title f-color-dark-black-s80"
                            >
                                {countFormatter(rowCount)}
                            </Typography.Text>
                        </Tooltip>
                    </InfoItem>
                )}
                {columnCount && (
                    <InfoItem title="Columns">
                        <Typography.Text className="f-text-title f-color-dark-black-s80">{columnCount}</Typography.Text>
                    </InfoItem>
                )}
                {queryCount && (
                    <InfoItem title="Monthly Queries">
                        <Typography.Text className="f-text-title f-color-dark-black-s80">{queryCount}</Typography.Text>
                    </InfoItem>
                )}
                {sortedUsers && sortedUsers.length > 0 && (
                    <InfoItem title="Top Users">
                        <div style={{ paddingTop: 8 }}>
                            <ExpandedActorGroup
                                containerStyle={{
                                    justifyContent: 'left',
                                }}
                                actors={
                                    sortedUsers
                                        .filter((user) => user && user?.user !== undefined && user?.user !== null)
                                        .map((user) => user?.user as CorpUser) || []
                                }
                                max={4}
                            />
                        </div>
                    </InfoItem>
                )}
            </Row>
            {lastUpdatedTime && (
                <InfoItem title="Last Updated" width="220px">
                    <Tooltip title={`Last reported at ${lastReportedTimeString}`}>
                        <Typography.Text strong style={{ fontSize: 16 }}>
                            {lastUpdatedTime}
                        </Typography.Text>
                    </Tooltip>
                </InfoItem>
            )}
        </StatSection>
    );
}
