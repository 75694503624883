import React from 'react';
import { Button } from 'antd';
import { ClockCircleOutlined, LineChartOutlined } from '@ant-design/icons';

import styled from 'styled-components';
import { ViewType } from './viewType';
import TabToolbar from '../../../components/styled/TabToolbar';
import { LookbackWindow } from './lookbackWindows';
import LookbackWindowSelect from './historical/LookbackWindowSelect';

const StatsHeaderContainer = styled.div`
    flex-grow: 1;
`;

type Props = {
    viewType: ViewType;
    setViewType: (type: ViewType) => void;
    reportedAt: string;
    lookbackWindow: LookbackWindow;
    setLookbackWindow: (window: LookbackWindow) => void;
};

export default function StatsHeader({ viewType, setViewType, reportedAt, lookbackWindow, setLookbackWindow }: Props) {
    const latestButtonClass = viewType === ViewType.LATEST ? 'f-color-blue-s100' : '';
    const latestButton = (
        <Button type="text" onClick={() => setViewType(ViewType.LATEST)}>
            <LineChartOutlined className={latestButtonClass} />
            <span className={latestButtonClass}>Latest</span>
        </Button>
    );

    const historicalButtonClass = viewType === ViewType.HISTORICAL ? 'f-color-blue-s100' : '';
    const historicalButton = (
        <Button type="text" onClick={() => setViewType(ViewType.HISTORICAL)}>
            <ClockCircleOutlined className={historicalButtonClass} />
            <span className={historicalButtonClass}>Historical</span>
        </Button>
    );

    const actionView =
        viewType === ViewType.HISTORICAL ? (
            <LookbackWindowSelect lookbackWindow={lookbackWindow} setLookbackWindow={setLookbackWindow} />
        ) : (
            <div className="f-text-content f-color-dark-black-s50">{reportedAt}</div>
        );

    return (
        <TabToolbar>
            <StatsHeaderContainer>
                {latestButton}
                {historicalButton}
            </StatsHeaderContainer>
            {actionView}
        </TabToolbar>
    );
}
