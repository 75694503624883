import { Avatar, Space } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { useGetUserQuery } from '../../../../../../graphql/user.generated';
import getAvatarColor from '../../../../../shared/avatar/getAvatarColor';

const AvatarStyled = styled(Avatar)<{ backgroundColor: string }>`
    color: #fff;
    background-color: ${(props) => props.backgroundColor};
`;

const UserAvatarColumn = ({ userUrn }: { userUrn: string }) => {
    const { data } = useGetUserQuery({ variables: { urn: userUrn, groupsCount: 0 } });

    return (
        <Space size="middle">
            <AvatarStyled size={25} backgroundColor={getAvatarColor(data?.corpUser?.info?.email || 'System')}>
                {data?.corpUser?.info?.fullName?.[0] ?? 'S'}
            </AvatarStyled>
            <div>{data?.corpUser?.info?.fullName ?? 'System'}</div>
        </Space>
    );
};

export default UserAvatarColumn;
