import styled from 'styled-components';
import { ANTD_GRAY } from '../../constants';

export default styled.div`
    background-color: #ffffff;
    display: flex;
    position: relative;
    z-index: 1;
    justify-content: space-between;
    height: 46px;
    border-bottom: 1px solid ${ANTD_GRAY[4.5]};
    padding: 7px 16px;
    flex: 0 0 auto;

    @media (max-width: 991px) {
        gap: 8px;
        height: auto;
        flex-wrap: wrap;
    }
`;
