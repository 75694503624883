import React from 'react';
import { Button, Table } from 'antd';
import styled from 'styled-components';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { ANTD_GRAY } from '../../constants';

interface ITablePagination {
    pagination: {
        fetchPrevPage: () => void;
        fetchNextPage: () => void;
        hasPrevPage: boolean;
        hasNextPage: boolean;
        renderTextPagination: () => string;
    };
}

const SourcePaginationContainer = styled.div`
    display: flex;
    padding: 8px;
    align-items: center;

    .icon-button {
        &.ant-btn {
            background-color: transparent;
        }
    }
`;

export const StyledTable = styled(Table)`
    overflow: inherit;
    height: inherit;

    &&& .ant-table-cell {
        background-color: #fff;
    }
    &&& .ant-table-thead .ant-table-cell {
        font-weight: 600;
        font-size: 12px;
        color: ${ANTD_GRAY[8]};
    }
    &&
        .ant-table-thead
        > tr
        > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        border: 1px solid ${ANTD_GRAY[4]};
    }
` as typeof Table;
// this above line preserves the Table component's generic-ness

export const TablePagination = (props: ITablePagination) => {
    const { fetchNextPage, fetchPrevPage, renderTextPagination, hasNextPage, hasPrevPage } = props.pagination;
    return (
        <SourcePaginationContainer>
            <Button
                type="default"
                disabled={hasPrevPage}
                className="icon-button"
                onClick={fetchPrevPage}
                icon={<LeftOutlined />}
            />
            <Button
                type="default"
                disabled={hasNextPage}
                className="icon-button"
                onClick={fetchNextPage}
                icon={<RightOutlined />}
            />
            <div className="f-text-content f-color-dark-black-s50 px-3">{renderTextPagination()}</div>
        </SourcePaginationContainer>
    );
};
