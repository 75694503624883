import React from 'react';
import { Col, Row } from 'antd';
import { AnalyticsChartGroup } from '../../../types.generated';
import { AnalyticsChart } from './AnalyticsChart';

type Props = {
    chartGroup: AnalyticsChartGroup;
};

export const ChartGroup = ({ chartGroup }: Props) => {
    return (
        <Row gutter={[16, 16]}>
            {chartGroup.charts.map((chart) => (
                <Col sm={24} md={24} lg={8} xl={8}>
                    <AnalyticsChart chartData={chart} width={400} height={260} />
                </Col>
            ))}
        </Row>
        // <div>
        //     {chartGroup.title?.length > 0 && (
        //         <TitleContainer>
        //             <GroupTitle level={3}>{chartGroup.title}</GroupTitle>
        //             <Divider />
        //         </TitleContainer>
        //     )}
        //     <Row>
        //         {chartGroup.charts.map((chart) => (
        //             <Col sm={24} md={24} lg={8} xl={8}>
        //                 <AnalyticsChart chartData={chart} width={300} height={300} />
        //             </Col>
        //         ))}
        //     </Row>
        // </div>
    );
};
